import dayjs from "dayjs";
import Markdown from "react-markdown";
import { Input, Table, DatePicker, Button, Flex, Divider } from "antd";

import StyledContainer from "./style";

import { ReactComponent as Checkbox } from "./images/checkbox.svg";
import { ReactComponent as CheckboxChecked } from "./images/checkbox_checked.svg";
import { ReactComponent as Date } from "./images/date.svg";
import { ReactComponent as Note } from "./images/note.svg";

const CheckboxContainer = ({
  id,
  readonly,
  toggleCheckbox,
  toggleOnValue,
  value,
}) => (
  <div
    className="checkbox-container"
    onClick={() =>
      !readonly &&
      toggleCheckbox({
        id,
        newValue: toggleOnValue,
      })
    }
    style={{ cursor: readonly ? "default" : "pointer" }}
  >
    {value === toggleOnValue ? <CheckboxChecked /> : <Checkbox />}
  </div>
);

const TableMilestones = ({
  data,
  handleCustomNameChange,
  handleCustomInputClick,
  handleDateChange,
  handleModalNoteOpen,
  hideNote,
  hideTitle,
  readonly,
  toggleCheckbox,
}) => {
  const columns = [
    {
      dataIndex: "label",
      render: (label, allValues) =>
        allValues.id.includes("_custom") && !readonly ? (
          <Input
            onChange={e =>
              handleCustomNameChange({
                id: allValues.id,
                newLabel: e.target.value,
              })
            }
            onClick={() =>
              handleCustomInputClick && handleCustomInputClick(allValues.id)
            }
            placeholder="Other..."
            value={label}
          />
        ) : (
          label
        ),
      width: "100%",
    },
    {
      align: "left",
      dataIndex: "date",
      hidden: !readonly,
      render: value => <b>{dayjs(value).format("MM/DD/YYYY")}</b>,
      title: "Date",
    },
    {
      align: readonly ? "center" : "right",
      // colSpan: readonly ? 1 : 2,
      dataIndex: "value",
      render: (value, allValues) => (
        <CheckboxContainer
          id={allValues.id}
          readonly={readonly}
          toggleCheckbox={toggleCheckbox}
          toggleOnValue="recent"
          value={value}
        />
      ),
      title: "Recent",
    },
    {
      align: readonly ? "center" : "left",
      colSpan: readonly ? 1 : 2,
      dataIndex: "value",
      render: (value, allValues) => (
        <CheckboxContainer
          id={allValues.id}
          readonly={readonly}
          toggleCheckbox={toggleCheckbox}
          toggleOnValue="upcoming"
          value={value}
        />
      ),
      title: "Upcoming",
    },
    {
      colSpan: 0,
      hidden: readonly,
      render: allValues => (
        <Flex gap={16}>
          <DatePicker
            allowClear={false}
            className={allValues.date && "active"}
            disabled={readonly}
            onChange={date => handleDateChange({ date, id: allValues.id })}
            suffixIcon={<Date />}
            value={allValues.date && dayjs(allValues.date)}
          />
          <Button
            className={allValues.note && "active"}
            disabled={readonly}
            icon={<Note />}
            onClick={() => handleModalNoteOpen(allValues.id)}
            shape="round"
            size="small"
          />
        </Flex>
      ),
    },
  ].filter(it => !it.hidden);

  return (
    <StyledContainer>
      <Table
        columns={columns}
        dataSource={data.filter(it => !it.hidden)}
        defaultExpandAllRows={true}
        showExpandColumn={false}
        expandable={{
          expandedRowRender: record => (
            <Flex className="container-note" vertical>
              <b>Note</b>
              <Divider />
              <Markdown>{record.note}</Markdown>
            </Flex>
          ),
          rowExpandable: record => !hideNote && !!record.note,
        }}
        pagination={false}
        rowHoverable={false}
      />
    </StyledContainer>
  );
};

export default TableMilestones;
