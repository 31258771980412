import { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import State from "../../../../../../context";
import {
  Button,
  Flex,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Table,
} from "antd";
import ModalConfirmRiskChange from "../../../../../manager/component/ModalProposalGuideManager/components/ModalConfirmRiskChange";
import SliderRisk from "../SliderRisk";

import StyledContainer from "./style";

import {
  calculateFinancialRiskToleranceMatrix,
  financialRiskToleranceCalculationData,
  QUIZ_RISK_SELECTION_TABLE_DATA,
} from "../../constant";

import { updateProductObjective } from "../../../../../../utils/request/regularApp";

import { ReactComponent as AddNote } from "../../image/icon_add_note.svg";

const SELECT_OPTIONS = [
  {
    value: "preservation",
    label: "Preservation",
  },
  {
    value: "conservative",
    label: "Conservative",
  },
  {
    value: "moderate",
    label: "Moderate",
  },
  {
    value: "growth",
    label: "Growth",
  },
  {
    value: "equity",
    label: "Equity",
  },
];

const getChartCalculations = value => {
  if (value < 10) {
    return [4, "preservation"];
  } else if (value < 20) {
    return [15, "conservative"];
  } else if (value < 30) {
    return [25, "moderate"];
  } else if (value < 40) {
    return [35, "growth"];
  } else {
    return [47, "equity"];
  }
};

const SectionRiskSelection = ({
  ButtonsContainer,
  proposalData,
  updateProposalData,
}) => {
  const [state] = useContext(State);
  const [formNote] = Form.useForm();
  const [formRiskAccounts] = Form.useForm();
  const [sliderValue, setSliderValue] = useState();
  const [
    sliderValueBasedOnRecommendationCalc,
    setSliderValueBasedOnRecommendationCalc,
  ] = useState();
  const [riskToleranceRecommendationName, setRiskToleranceRecommendationName] =
    useState("");
  const [openedModalConfirmation, setOpenedModalConfirmation] = useState(false);
  const [showAddNoteSection, setShowAddNoteSection] = useState(false);

  useEffect(() => {
    const calculatedRiskTolerance =
      calculateFinancialRiskToleranceRecommendation();

    updateProposalData("calculatedRiskTolerance", calculatedRiskTolerance);
    formRiskAccounts.setFieldsValue(proposalData?.listAccounts);
  }, []);

  useEffect(() => {
    updateProposalData("riskTolerance", riskToleranceRecommendationName);
  }, [riskToleranceRecommendationName]);

  useEffect(() => {
    setSliderValue(sliderValueBasedOnRecommendationCalc);
  }, [sliderValueBasedOnRecommendationCalc]);

  const calculateFinancialRiskToleranceRecommendation = () => {
    const currentAgeRange =
      financialRiskToleranceCalculationData.currentAgeRange[
        proposalData.currentAgeRange
      ];
    const beginWithdrawal =
      financialRiskToleranceCalculationData.beginWithdrawal[
        proposalData.beginWithdrawal
      ];
    const incomeExpectations =
      financialRiskToleranceCalculationData.incomeExpectations[
        proposalData.incomeExpectations
      ];

    const investmentKnowledge =
      financialRiskToleranceCalculationData.investmentKnowledge[
        proposalData.investmentKnowledge
      ];
    const decisionInfluence =
      financialRiskToleranceCalculationData.decisionInfluence[
        proposalData.decisionInfluence
      ];
    const volatilityTolerance =
      financialRiskToleranceCalculationData.volatilityTolerance[
        proposalData.volatilityTolerance
      ];
    const decliningMarketWithholdingPeriod =
      financialRiskToleranceCalculationData.decliningMarketWithholdingPeriod[
        proposalData.decliningMarketWithholdingPeriod
      ];

    const totalPart1 = currentAgeRange + beginWithdrawal + incomeExpectations;
    const totalPart2 =
      investmentKnowledge +
      decisionInfluence +
      volatilityTolerance +
      decliningMarketWithholdingPeriod;

    let matrixPositionY, matrixPositionX;

    if (totalPart1 <= 5) {
      matrixPositionY = 0;
    } else if (totalPart1 <= 10) {
      matrixPositionY = 1;
    } else if (totalPart1 <= 16) {
      matrixPositionY = 2;
    } else if (totalPart1 <= 21) {
      matrixPositionY = 3;
    } else if (totalPart1 <= 25) {
      matrixPositionY = 4;
    } else {
      matrixPositionY = 5;
    }

    if (totalPart2 <= 8) {
      matrixPositionX = 0;
    } else if (totalPart2 <= 11) {
      matrixPositionX = 1;
    } else if (totalPart2 <= 14) {
      matrixPositionX = 2;
    } else if (totalPart2 <= 17) {
      matrixPositionX = 3;
    } else if (totalPart2 <= 20) {
      matrixPositionX = 4;
    } else if (totalPart2 <= 23) {
      matrixPositionX = 5;
    } else if (totalPart2 <= 27) {
      matrixPositionX = 6;
    } else if (totalPart2 <= 31) {
      matrixPositionX = 7;
    } else if (totalPart2 <= 35) {
      matrixPositionX = 8;
    } else if (totalPart2 <= 38) {
      matrixPositionX = 8;
    } else if (totalPart2 <= 41) {
      matrixPositionX = 10;
    } else if (totalPart2 <= 44) {
      matrixPositionX = 11;
    } else if (totalPart2 <= 47) {
      matrixPositionX = 12;
    } else {
      matrixPositionX = 13;
    }

    const riskToleranceStrategyRecommendation =
      calculateFinancialRiskToleranceMatrix[matrixPositionY][matrixPositionX];

    switch (riskToleranceStrategyRecommendation) {
      case "P":
        setSliderValueBasedOnRecommendationCalc(3);
        setRiskToleranceRecommendationName("preservation");
        return "preservation";
      case "C":
        setSliderValueBasedOnRecommendationCalc(15);
        setRiskToleranceRecommendationName("conservative");
        return "conservative";
      case "M":
        setSliderValueBasedOnRecommendationCalc(25);
        setRiskToleranceRecommendationName("moderate");
        return "moderate";
      case "G":
        setSliderValueBasedOnRecommendationCalc(35);
        setRiskToleranceRecommendationName("growth");
        return "growth";
      case "E":
        setSliderValueBasedOnRecommendationCalc(47);
        setRiskToleranceRecommendationName("equity");
        return "equity";
    }
  };

  const handleFormValuesChange = useDebouncedCallback(
    (changedValues, allValues) => {
      updateProposalData("listAccounts", allValues);
    },
    500
  );

  const handleNoteAdd = () => {
    setShowAddNoteSection(true);
    formNote.setFieldValue("note", proposalData.note);
  };

  const handleNoteCancel = () => {
    setShowAddNoteSection(false);
  };

  const handleNoteSave = ({ note }) => {
    state.setKeyValue("loading", true);

    const activeUserData = state.managedUsers?.find(
      it => it.email === state.activeEmail
    );

    const payload = activeUserData
      ? {
          // payload from advisor account
          email: activeUserData.email,
          ...activeUserData.valueMap.prospectObjective,
          note,
        }
      : {
          // payload from user account
          email: state._id,
          ...state.preferences.valueMap.prospectObjective,
          note,
        };

    updateProductObjective(payload)
      .then(() => {
        state.setKeyValue("loading", false);
        state.showSuccess("Your note has been saved");
        updateProposalData("note", note);
      })
      .catch(error => {
        state.showError(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleRiskChangeConfirm = () => {
    const [roundedSliderValue, riskToleranceType] =
      getChartCalculations(sliderValue);
    setSliderValue(roundedSliderValue);
    updateProposalData("riskTolerance", riskToleranceType);
    setOpenedModalConfirmation(false);
  };

  const handleRiskChangeCancel = () => {
    setSliderValue(sliderValueBasedOnRecommendationCalc);
    updateProposalData("riskTolerance", riskToleranceRecommendationName);
    setOpenedModalConfirmation(false);
  };

  const SectionNote = () =>
    showAddNoteSection ? (
      <Form
        form={formNote}
        id="formNote"
        onFinish={handleNoteSave}
        style={{ width: "100%" }}
      >
        <Flex gap={12} style={{ marginTop: 20, width: "100%" }} vertical>
          <Form.Item name="note">
            <Input.TextArea style={{ height: 131, maxWidth: 542 }} />
          </Form.Item>
          <Flex gap={20}>
            <Button
              htmlType="submit"
              shape="round"
              size="small"
              style={{ background: "#192849", fontSize: 16, width: 106 }}
              type="primary"
            >
              Save note
            </Button>
            <Button
              shape="round"
              size="small"
              style={{ color: "#19284950", fontSize: 16 }}
              type="text"
              onClick={handleNoteCancel}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Form>
    ) : (
      <Flex justify="center">
        <Button
          className="button-add-note"
          icon={<AddNote />}
          onClick={handleNoteAdd}
          shape="round"
          size="large"
        >
          Add note
        </Button>
      </Flex>
    );

  return (
    <StyledContainer gap={50} justify="space-between" wrap="wrap">
      <Flex style={{ width: 400 }} vertical>
        <h3 className="title" style={{ margin: "0 0 10px" }}>
          Risk Selection
        </h3>
        <p className="description" style={{ margin: "0 0 52px" }}>
          Based on your answers, your calculated risk score is{" "}
          <b style={{ textTransform: "capitalize" }}>
            {proposalData.calculatedRiskTolerance}.
          </b>
        </p>
        <Radio.Group
          onChange={e =>
            updateProposalData("acceptedRiskLevel", e.target.value)
          }
          value={proposalData.acceptedRiskLevel}
        >
          <Flex gap={20} vertical>
            <Radio value="recommended">
              All accounts should be invested according to my measured risk
              level:{" "}
              <b style={{ textTransform: "capitalize" }}>
                {getChartCalculations(sliderValue)[1]}
              </b>
            </Radio>
            <Radio value="custom">
              I wish to deviate from my measured risk level and request that my
              accounts be invested as indicated
            </Radio>
          </Flex>
        </Radio.Group>
        <ButtonsContainer />
      </Flex>

      <Flex style={{ width: 431 }} vertical>
        <Flex
          gap={24}
          style={{
            display:
              proposalData.acceptedRiskLevel === "custom" ? "flex" : "none",
          }}
          vertical
        >
          <p>
            Please adequately describe each account below, including the{" "}
            <b>last 4 digits of the account number</b> where appropriate. If you
            are to recommend alternative investments be incorporated into a
            given risk tolerance, please list the corresponding account(s), risk
            tolerance(s), and “with alternatives” by clicking “Add a note.”
          </p>
          <Form
            form={formRiskAccounts}
            id="formRiskAccounts"
            layout="vertical"
            onValuesChange={handleFormValuesChange}
            style={{
              width: "100%",
            }}
          >
            <Flex gap={15}>
              <Flex style={{ width: "100%" }} vertical>
                <span className="row-account-label">Account</span>
                <Form.Item name="account__name__1">
                  <Input placeholder="Name of account..." size="large" />
                </Form.Item>
              </Flex>
              <Flex vertical>
                <span className="row-account-label">Last four</span>
                <Form.Item name="account__lastFour__1">
                  <InputNumber
                    controls={false}
                    formatter={value => value.slice(-4)}
                    min={0}
                  />
                </Form.Item>
              </Flex>
              <Flex vertical>
                <span className="row-account-label">Risk tolerance</span>
                <Form.Item name="account__riskType__1">
                  <Select options={SELECT_OPTIONS} size="large" />
                </Form.Item>
              </Flex>
            </Flex>
            <Flex gap={15}>
              <Form.Item name="account__name__2" style={{ width: "100%" }}>
                <Input placeholder="Name of account..." size="large" />
              </Form.Item>
              <Form.Item name="account__lastFour__2">
                <InputNumber
                  controls={false}
                  formatter={value => value.slice(-4)}
                  min={0}
                />
              </Form.Item>
              <Form.Item name="account__riskType__2">
                <Select options={SELECT_OPTIONS} size="large" />
              </Form.Item>
            </Flex>
            <Flex gap={15}>
              <Form.Item name="account__name__3" style={{ width: "100%" }}>
                <Input placeholder="Name of account..." size="large" />
              </Form.Item>
              <Form.Item name="account__lastFour__3">
                <InputNumber
                  controls={false}
                  formatter={value => value.slice(-4)}
                  min={0}
                />
              </Form.Item>
              <Form.Item name="account__riskType__3">
                <Select options={SELECT_OPTIONS} size="large" />
              </Form.Item>
            </Flex>
            <Flex gap={15}>
              <Form.Item name="account__name__4" style={{ width: "100%" }}>
                <Input placeholder="Name of account..." size="large" />
              </Form.Item>
              <Form.Item name="account__lastFour__4">
                <InputNumber
                  controls={false}
                  formatter={value => value.slice(-4)}
                  min={0}
                />
              </Form.Item>
              <Form.Item name="account__riskType__4">
                <Select options={SELECT_OPTIONS} size="large" />
              </Form.Item>
            </Flex>
            <Flex gap={15}>
              <Form.Item name="account__name__5" style={{ width: "100%" }}>
                <Input placeholder="Name of account..." size="large" />
              </Form.Item>
              <Form.Item name="account__lastFour__5">
                <InputNumber
                  controls={false}
                  formatter={value => value.slice(-4)}
                  min={0}
                />
              </Form.Item>
              <Form.Item name="account__riskType__5">
                <Select options={SELECT_OPTIONS} size="large" />
              </Form.Item>
            </Flex>
          </Form>
        </Flex>
        <div
          className="container-custom-risk"
          style={{
            display:
              proposalData.acceptedRiskLevel !== "custom" ? "block" : "none",
          }}
        >
          <SliderRisk
            riskLeft={proposalData.calculatedRiskTolerance}
            riskRight={getChartCalculations(sliderValue)[1]}
            onChange={value => setSliderValue(value)}
            onAfterChange={() => setOpenedModalConfirmation(true)}
            value={sliderValue}
          />

          <Table
            columns={[
              {
                dataIndex: "riskTolerance",
                title: "Risk tolerance",
              },
              {
                align: "right",
                dataIndex: "equity",
                title: "Equity",
              },
              {
                align: "right",
                dataIndex: "fixedIncome",
                title: "Fixed Income",
              },
              {
                align: "right",
                dataIndex: "alternatives",
                title: "Alternatives",
              },
            ]}
            dataSource={QUIZ_RISK_SELECTION_TABLE_DATA}
            pagination={false}
            rowClassName={row =>
              row.key === getChartCalculations(sliderValue)[1] ? "active" : ""
            }
            rowHoverable={false}
            size="small"
          />
        </div>

        <SectionNote />
      </Flex>

      <ModalConfirmRiskChange
        onCancel={handleRiskChangeCancel}
        onSuccess={handleRiskChangeConfirm}
        open={openedModalConfirmation}
      />
    </StyledContainer>
  );
};

export default SectionRiskSelection;
