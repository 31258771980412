import { useEffect, useState } from "react";
import { Flex, Tabs, Tag } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SectionHistorical from "./components/SectionHistorical";
import SectionPlanning from "./components/SectionPlanning";
import SectionRisk from "./components/SectionRisk";

import StyledModal from "./style";

import { FILTER_TAG_COLOR_LIST } from "../../constants";

import { getEtfByTicker, getLiveAssessmentV2, getMc } from "../../request";

import { CloseOutlined } from "@ant-design/icons";
import { ReactComponent as InUseIcon } from "./image/in_use.svg";
import { faBookmark as faBookmarkSolid } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarkRegular } from "@fortawesome/free-regular-svg-icons";

const getProductTicker = product => {
  let ticker = product.ticker;

  if (!ticker) {
    switch (product.name) {
      case "Level Aggressive 100":
        ticker = "SOXL";
        break;
      case "Level Growth 80/20":
        ticker = "XLF";
        break;
      case "Level Moderate 60/40":
        ticker = "XME";
        break;
      case "Level Conservative 40/60":
        ticker = "AGG";
        break;
      case "Level Preservation 20/80":
        ticker = "TIP";
    }
  }

  return ticker;
};

const ModalLevelProductDetails = ({
  handleClose,
  investmentAssumptions,
  open,
  product,
  showError,
}) => {
  const [calculationLiveAssessment, setCalculationLiveAssessment] = useState();
  const [calculationMc, setCalculationMc] = useState();
  const [etfData, setEtfData] = useState();
  const [loading, setLoading] = useState(false);

  const updatedProduct = product && {
    ...product,
    ticker: getProductTicker(product),
  };

  useEffect(() => {
    if (!product || !open) return;

    setLoading(true);

    getEtfByTicker({ ticker: updatedProduct.ticker })
      .then(setEtfData)
      .catch(showError);

    const requestBody = {
      ...investmentAssumptions,
      organization: "level2",
      productId: product._id ?? product.productId,
    };

    getLiveAssessmentV2(requestBody)
      .then(data => {
        setCalculationLiveAssessment({
          ...data,
          productId: product._id,
        });

        getMc(requestBody)
          .then(setCalculationMc)
          .catch(showError)
          .finally(() => setLoading(false));
      })
      .catch(error => {
        showError(error);
        setLoading(false);
      });
  }, [open, product]);

  const items = [
    {
      children: (
        <SectionHistorical
          etfData={etfData}
          loading={loading}
          product={updatedProduct}
        />
      ),
      key: "historical",
      label: "Historical",
    },
    {
      children: product && (
        <SectionPlanning
          calculationLiveAssessment={calculationLiveAssessment}
          calculationMc={calculationMc}
          etfData={etfData}
          loading={loading}
          product={updatedProduct}
        />
      ),
      key: "planning",
      label: "Planning",
    },
    {
      children: (
        <SectionRisk
          etfData={etfData}
          loading={loading}
          product={updatedProduct}
        />
      ),
      key: "risk",
      label: "Risk",
    },
  ];

  return (
    <StyledModal
      closeIcon={null}
      footer={null}
      onCancel={handleClose}
      open={open}
      title={
        <Flex gap={8} vertical>
          <Flex justify="space-between">
            <h3>
              {product?.name ?? `${product?.riskTolerance} Growth Rate`}
              {product?.ticker && (
                <span style={{ fontWeight: 400 }}> ({product?.ticker})</span>
              )}
            </h3>
            <Flex align="center" gap={32}>
              <FontAwesomeIcon
                icon={
                  product?.customFilter === "My Favorites"
                    ? faBookmarkSolid
                    : faBookmarkRegular
                }
                style={{ fontSize: 18 }}
              />
              <CloseOutlined
                onClick={handleClose}
                style={{ color: "#818181", fontSize: 24 }}
              />
            </Flex>
          </Flex>
          <Flex gap={8}>
            <Tag color="#D7D7D7" style={{ color: "#052C2B" }}>
              {product?.type}
            </Tag>
            <Tag
              color={
                FILTER_TAG_COLOR_LIST.find(
                  it => it.label === product?.broadCategory
                )?.color
              }
            >
              {product?.broadCategory}
            </Tag>
            <Tag color="#02494E" icon={<InUseIcon />}>
              In Use
            </Tag>
          </Flex>
        </Flex>
      }
      width={790}
      zIndex={1000}
    >
      <Tabs items={items} />
    </StyledModal>
  );
};

export default ModalLevelProductDetails;
