import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context";
import { Button, Input, Table, theme } from "antd";
import ConvertClientDialog from "../ConvertClientDialog";
import MenuActions from "../../../../../../atom/MenuActions";
import ModalArchiveManagedUser from "../../../../../../molecule/ModalArchiveManagedUser";
import ModalSendSurveyLink from "../../../../../oneascent/page/PageProposalDashboard/components/ModalSendSurveyLink";
import ModalVbi from "../../../../../oneascent/component/ModalVbi";
import ModalViewIpq from "../../../../../oneascent/component/ModalViewIpq";

import StylesContainer from "./style";

import {
  archiveLead,
  openProposal,
} from "../../../../../../utils/request/manager";
import {
  getLastSavedProposalProps,
  getProspectUsers,
  isAdminView,
  isManagerTypeIpq,
  isOrganizationOneascent,
  openProposalWithConfig,
} from "../../../../../../utils/helper/specialized";
import { getCurrencyValue } from "../../../../../../utils/helper/general";
import {
  downloadVbiFile,
  getQuizLink,
  sendQuizLinkOnEmail,
} from "../../../../../oneascent/request";
import { getManagedUserAssets } from "../../../../../oneascent/utils";

const ProspectsTable = ({ instanceLabel }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");
  const [clientJwt, setClientJwt] = useState();

  const handleArchiveClient = email => {
    state.closeModal("archiveManagedUserModal");
    state.setKeyValue("loading", true);

    archiveLead({
      email: state.activeEmail,
      userManagerEmail: state._id,
    })
      .then(() =>
        state.setAdminData().then(() => {
          state.setKeyValue("loading", false);
          state.setKeyValue("activeEmail", null);
          state.showSuccess(`Client ${state.activeEmail} archived`);
        })
      )
      .catch(error => {
        state.showError(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleActionArchiveClient = email => {
    state.setKeyValue("activeEmail", email);
    state.openModal("archiveManagedUserModal");
  };

  const handleActionConvertUser = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("openModalConvertUser", true);
  };

  const handleActionEditUser = email => {
    state.setKeyValue("activeEmail", email);

    if (isOrganizationOneascent(state.organization?.name)) {
      state.openModal("addProspectModal");
    } else {
      state.openModal("openModalClientDetails");
    }
  };

  const handleActionNewIpq = email => {
    state.setKeyValue("loading", true);
    state.setKeyValue("activeEmail", email);

    getQuizLink({
      userId: email,
      userManagerId: state._id,
      userManagerFullName: "",
      route: "",
      valueMapKey: "",
      domain: "",
    })
      .then(link => {
        setClientJwt(link.split("jwt=")[1]);
        state.setKeyValue("openModalSurveyLink", true);
      })
      .catch(state.showError)
      .finally(() => state.setKeyValue("loading", false));
  };

  const handleActionVbi = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("open_modal_vbi", true);
  };

  const handleActionViewIpq = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("openModalViewIpq", true);
  };

  const handleCreateProposalButton = email => {
    const proposalData = state?.managedUsers?.find(it => it.email === email);

    if (
      !proposalData?.valueMap?.guideInitialData ||
      proposalData?.valueMap?.guideInitialData?.enableOnboardingQuestions
    ) {
      state.setKeyValue("activeEmail", email);
      state.setKeyValue("openModalProposalGuide", true);
    } else {
      openProposalWithConfig({
        organization: state?.organization?.name,
        prospectType: state?.onboardingQuestionnaireType,
        userEmail: email,
      });
    }
  };

  const handleCopyLink = () => {
    const link = `${location.origin}/questionnaire-proposal?email=${state.activeEmail}&assessmentLabel="IPQ"&valueMapKey=prospectObjective&jwt=${clientJwt}`;
    navigator?.clipboard?.writeText(link);
    state.showSuccess("Link copied");
  };

  const handleSurveyLinkCancel = () => {
    state.setKeyValue("openModalSurveyLink", false);
  };

  const handleSurveyLinkContinue = () => {
    state.setKeyValue("openModalSurveyLink", false);
    state.setKeyValue("newIpqMode", true);
    state.setKeyValue("openModalProposalGuide", true);
  };

  const handleSurveyLinkSend = ({ email }) => {
    if (!email) {
      state.showWarning("Please, fill 'email' field before sending");
      return;
    }

    state.setKeyValue("loading", true);

    sendQuizLinkOnEmail({
      emailDestination: email,
      userId: state.activeEmail,
      userManagerId: state._id,
      userManagerFullName: `${
        state.personalInfo?.firstName ?? ""
      } ${state.personalInfo?.lastName ?? ""}`,
      route: "questionnaire-proposal",
      valueMapKey: "prospectObjective",
      domain: location.host,
      assessmentLabel: "",
    })
      .then(() => {
        state.showSuccess(`Email sent to: ${email}`);
      })
      .catch(state.showError)
      .finally(() => state.setKeyValue("loading", false));
  };

  const getTableData = () =>
    getProspectUsers(state).map(it => {
      const lastSavedProposalProps = getLastSavedProposalProps(
        it.valueMap?.productMap
      );

      return {
        ...it,
        assets: lastSavedProposalProps?.timestamp && getManagedUserAssets(it), // only for saved proposals
        email: it.email,
        key: it.email,
        name: it.lastName + ", " + it.firstName,
        proposalGenerated: lastSavedProposalProps?.timestamp,
        prospectObjective: it.valueMap?.prospectObjective,
      };
    });

  const columns = [
    {
      dataIndex: "name",
      filteredValue: [searchedText],
      onFilter: (value, record) =>
        String(record.name).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()),
      render: (text, restValues) =>
        restValues.proposalGenerated ||
        isOrganizationOneascent(state.organization?.name) ? (
          <a
            onClick={() =>
              openProposal({
                userEmail: restValues.email,
                organization: state.organization?.name,
              })
            }
          >
            {text}
          </a>
        ) : (
          <b>{text}</b>
        ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      title: `${instanceLabel[0].toUpperCase() + instanceLabel.slice(1)} Name`,
    },
    {
      dataIndex: "email",
      render: value => <a href={`mailto:${value}`}>{value}</a>,
      sorter: (a, b) => a.email.localeCompare(b.email),
      title: "Email",
    },
    {
      align: "left",
      hidden: !isManagerTypeIpq(state.onboardingQuestionnaireType),
      render: allValues =>
        allValues.prospectObjective && (
          <a onClick={() => handleActionViewIpq(allValues.email)}>View</a>
        ),
      sorter: (a, b) =>
        !!a.valueMap?.prospectObjective - !!b.valueMap?.prospectObjective,
      title: "IPQ",
    },
    {
      render: allValues => {
        const vbi_status = allValues.vbi?.statusAdvisorView;

        if (vbi_status === "View")
          return (
            <a
              onClick={() =>
                downloadVbiFile({
                  advisor: state._id,
                  client: allValues.email,
                })
              }
            >
              View
            </a>
          );

        return vbi_status;
      },
      sorter: (a, b) =>
        (a.vbi?.statusAdvisorView ?? "")?.localeCompare(
          b.vbi?.statusAdvisorView ?? ""
        ),
      title: "VBI Audit",
    },
    {
      align: "right",
      className: "assets-column",
      dataIndex: "assets",
      render: value => getCurrencyValue(value),
      sorter: (a, b) => a.assets - b.assets,
      title: "Assets",
    },
    {
      align: "right",
      dataIndex: "proposalGenerated",
      render: (value, restValues) =>
        value ? (
          dayjs(value).format("MM/DD/YYYY")
        ) : (
          <Button
            shape="round"
            size="small"
            style={{
              borderColor: token.color_secondary_button,
              color: token.color_secondary_button,
            }}
            onClick={() => handleCreateProposalButton(restValues.email)}
            disabled={isAdminView()}
          >
            Create Proposal
          </Button>
        ),
      sorter: (a, b) => dayjs(a.proposalGenerated) - dayjs(b.proposalGenerated),
      title: "Proposal Generated",
      width: 150,
    },
    {
      className: "actions-menu-table-cell",
      render: allValues => (
        <MenuActions
          items={[
            {
              key: "edit",
              label: (
                <span style={{ textTransform: "capitalize" }}>
                  Edit {instanceLabel}
                </span>
              ),
              onClick: () => handleActionEditUser(allValues.email),
            },
            {
              key: "move",
              label: "Mark as Client",
              onClick: () => handleActionConvertUser(allValues.email),
            },
            {
              hidden: !isOrganizationOneascent(state.organization?.name),
              key: "vbi",
              label: "VBI Audit",
              onClick: () => handleActionVbi(allValues.email),
            },
            {
              hidden: !isManagerTypeIpq(state.onboardingQuestionnaireType),
              key: "newIpq",
              label: "New IPQ",
              onClick: () => handleActionNewIpq(allValues.email),
            },
            {
              type: "divider",
            },
            {
              key: "archive",
              label: <span style={{ color: "red" }}>Archive</span>,
              onClick: () => handleActionArchiveClient(allValues.email),
            },
          ].filter(it => !it.hidden)}
        />
      ),
      title: "",
      width: 40,
    },
  ].filter(it => !it.hidden);

  return (
    <StylesContainer>
      <Input
        style={{
          borderRadius: 25,
          height: 37,
          marginBottom: 16,
          maxWidth: 290,
          textTransform: "capitalize",
        }}
        placeholder={`Search ${instanceLabel}s...`}
        onChange={event => setSearchedText(event.target.value)}
      />
      <Table
        columns={columns}
        dataSource={getTableData()}
        loading={state.loading}
        pagination={false}
        rowHoverable={false}
      />

      <ConvertClientDialog
        email={state.activeEmail}
        handleClose={() => state.setKeyValue("openModalConvertUser", false)}
        open={state.openModalConvertUser}
      />
      <ModalArchiveManagedUser
        instanceName={instanceLabel}
        loading={state.loading}
        onCancel={() => state.closeModal("archiveManagedUserModal")}
        onConfirm={handleArchiveClient}
        open={state.archiveManagedUserModal}
      />
      <ModalSendSurveyLink
        email={state.activeEmail}
        handleCopyLink={handleCopyLink}
        loading={state.loading}
        open={state.openModalSurveyLink}
        onCancel={handleSurveyLinkCancel}
        onContinue={handleSurveyLinkContinue}
        onSend={handleSurveyLinkSend}
      />
      <ModalVbi
        onCancel={() => state.setKeyValue("open_modal_vbi", false)}
        open={state.open_modal_vbi}
      />
      <ModalViewIpq
        activeUserData={state.managedUsers?.find(
          it => it.email === state.activeEmail
        )}
        onCancel={() => state.setKeyValue("openModalViewIpq", false)}
        open={state.openModalViewIpq}
      />
    </StylesContainer>
  );
};

export default ProspectsTable;
