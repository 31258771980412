import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import State from "../context";
import { notification } from "antd";
import Overlay from "../atom/Overlay";

import defaultDashboardStateData from "../context/defaultState.json";

import {
  getErrorMessageCopy,
  getManagerPendoAccountId,
  getManagerPendoVisitorMetadata,
  initializePendo,
  isAdminView,
  isGoalsDomain,
  isOrganizationLevel,
  isOrganizationOneascent,
  isProposalUser,
  setTenantSpecificHeadAttributes,
} from "../utils/helper/specialized";
import {
  getFinancialProducts,
  getSubscriptionType,
  getUserData,
  saveUserPreferences,
} from "../utils/request/regularApp";
import UI from "./UI";
import {
  editLead,
  getOrganizationManagerData,
  getUserManagerData,
} from "../utils/request/manager";
import { getGoalsAppOrganization } from "../ecosystem/goals/helpers";
import { getQuizLink } from "../ecosystem/oneascent/request";

const StateProvider = ({ children, manager, organization }) => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [state, setState] = useState(defaultDashboardStateData);

  useEffect(() => {
    setKeyValue("session", uuidv4());

    // set metadata based on the routing type, before user data responded
    organization && setTenantSpecificHeadAttributes(organization);
  }, []);

  useEffect(() => {
    if (searchParams.get("jwt")) {
      // abort handling for public url
      setKeyValue("showOverlay", false);
      return;
    }

    setKeyValue("showOverlay", true);

    if (manager) {
      setKeyValue("isManager", true);
      setKeyValue("artBoardModal", true);

      setAdminData().then(data => {
        if (!data?._id) {
          // no manager data responded
          navigate("/login");
          return;
        }

        if (
          isOrganizationOneascent(data.organization?.name) &&
          data.resetPassword
        ) {
          setKeyValue("openModalManagerSettings", true);
        }

        setTenantSpecificHeadAttributes(
          data.organization?.name ?? data.orgName
        );

        initializePendo(getManagerPendoVisitorMetadata(data), {
          id: getManagerPendoAccountId(data),
        });

        getSubscriptionType(data._id).then(subscriptionTypeResponse => {
          setState(lastState => ({
            ...lastState,
            ...subscriptionTypeResponse,
          }));

          getFinancialProducts(
            organization ?? data.organization?.name ?? data.orgName
          ).then(data => {
            setKeyValue("productsList", data);
            setKeyValue("showOverlay", false);
          });
        });

        data.managedUsers?.map(it => {
          if (
            it?.valueMap?.leadInitial?.isNewLead &&
            it?.valueMap?.leadInitial?.isNotifyAdvisor
          ) {
            api.info({
              message: "A new lead has been added to your Leads page",
              placement: "topRight",
              description: `Lead email: ${it.email}`,
            });

            editLead({
              ...it,
              leadInitial: {
                ...it.valueMap.leadInitial,
                isNewLead: false,
              },
            });
          }
        });
      });
    } else {
      setUserData().then(data => {
        if (!data?._id) {
          // no user data responded
          navigate("/login");
          return;
        }

        setTenantSpecificHeadAttributes(
          data.organization?.name ?? data.orgName
        );

        if (isGoalsDomain()) {
          initializePendo({ id: data._id }, { id: "Goals" });
        } else if (isProposalUser(data)) {
          if (isOrganizationOneascent(data.organization?.name ?? data.orgName))
            initializePendo(
              {
                id: data.userManagement.linkedUserManagers[0].email,
                email: data.userManagement.linkedUserManagers[0].email,
                firstname: "",
                lastname: "",
                plantype: "Advisor",
                advisorview: `Client proposal: ${
                  data.personalInfo?.firstName ?? ""
                } ${data.personalInfo?.lastName ?? ""}`,
              },
              { id: `${data.organization?.name} Asset Manager` }
            );
        }

        if (data.preferences?.valueMap?.level2Map) {
          // backward compatibility
          setState(prevState => ({
            ...prevState,
            investmentAssumptions: {
              ...prevState.investmentAssumptions,
              ...data.preferences.valueMap.level2Map["initial"],
            },
          }));
        }

        getFinancialProducts(
          isOrganizationLevel(
            organization ?? data.organization?.name ?? data.orgName
          )
            ? "Level"
            : getGoalsAppOrganization(
                organization ?? data.organization?.name ?? data.orgName
              )
        )
          .then(data => setKeyValue("productsList", data))
          .catch(console.log);

        if (data.userManagement?.linkedUserManagers?.length) {
          getSubscriptionType(data.userManagement.linkedUserManagers[0].email)
            .then(response => setKeyValue("managerAccess", response?.access))
            .catch(console.log);

          getUserManagerData()
            .then(data => {
              setKeyValue("userManagerData", data);
              setKeyValue("organization", data.organization);
            })
            .catch(console.log);

          // touch this endpoint to get jwt
          getQuizLink({
            userId: data._id,
            userManagerId: data.userManagement.linkedUserManagers[0].email,
            userManagerFullName: "",
            route: "",
            valueMapKey: "",
            domain: "",
          })
            .then(link => setKeyValue("jwt", link.split("jwt=")[1]))
            .catch(console.log);
        }

        setKeyValue("showOverlay", false);
      });
    }
  }, []);

  const closeModal = modalKey => {
    setState(prevState => ({
      ...prevState,
      [modalKey]: false,
    }));
  };

  const getPreferenceValue = searchKey =>
    state.preferences?.valueMap && state.preferences.valueMap[searchKey];

  const openModal = modalKey =>
    setState(prevState => ({
      ...prevState,
      [modalKey]: true,
    }));

  const setAdminData = () =>
    (localStorage.getItem("collection") === "OrgManager" && !isAdminView()
      ? getOrganizationManagerData
      : getUserManagerData)()
      .then(data => {
        setState(oldState => ({
          ...oldState,
          ...data,
          managedUsers: data.managedUsers ?? [],
          managedUserManagers: data.managedUserManagers ?? [],
          loading: false,
        }));

        return data;
      })
      .catch(({ message }) => {
        showWarning(message);
        setTimeout(() => navigate("/login"), 3000);
      });

  const setKeyValue = (key, value) =>
    setState(lastState => ({
      ...lastState,
      [key]: value,
    }));

  const setPreferenceValue = (key, value) =>
    saveUserPreferences({
      ...state.preferences,
      valueMap: {
        ...state.preferences.valueMap,
        [key]: value,
      },
    })
      .then(() => setUserData())
      .catch(error => state.showError(error));

  const setUserData = () =>
    getUserData()
      .then(data => {
        setState(prevState => ({
          ...prevState,
          ...data,
        }));

        return data;
      })
      .catch(({ message }) => {
        showWarning(message);
        setTimeout(() => navigate("/login"), 3000);
      });

  const showError = error => {
    console.log(error);
    console.log(typeof error);

    api.error({
      message: getErrorMessageCopy(error),
      placement: "topRight",
      description: "",
    });
  };

  const showSuccess = successMessage =>
    api.success({
      message: successMessage,
      placement: "topRight",
      description: "",
    });

  const showWarning = errorMessage =>
    api.warning({
      message: errorMessage,
      placement: "topRight",
      description: "",
    });

  const mergedState = {
    ...state,
    closeModal,
    getPreferenceValue,
    openModal,
    setAdminData,
    setKeyValue,
    setPreferenceValue,
    setUserData,
    showError,
    showSuccess,
    showWarning,
  };

  return (
    <State.Provider value={[mergedState, setState]}>
      <UI state={state}>
        <Overlay loading={state.showOverlay}>{children}</Overlay>
      </UI>
      {contextHolder}
    </State.Provider>
  );
};

export default StateProvider;
