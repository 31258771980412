import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import State from "../../../../../../context";
import { Button, Flex } from "antd";
import ArticleGenerosity from "./component/ArticleGenerosity";
import ArticleInvesting from "./component/ArticleInvesting";
import ArticleMilestones from "./component/ArticleMilestones";
import ArticlePerspectives from "./component/ArticlePerspectives";
import ArticlePriorities from "./component/ArticlePriorities";
import CardNav from "./component/CardNav";
import ModalCreateReport from "../../../../component/ModalCreateReport";
import ModalPerspectives from "../ModalPerspectives";
import ModalPriorities from "../ModalPriorities";
import ModalProposalGuideUser from "../../../../../proposal/component/ModalProposalGuideUser";
import ModalSendSurveyLink from "../ModalSendSurveyLink";
import ModalViewIpq from "../../../../component/ModalViewIpq";

import StylesContainer from "./style";

import { LIST_DASHBOARD_TABS_DATA } from "./constant";

import { sendQuizLinkOnEmail } from "../../../../request";

import { ReactComponent as IconPdf } from "../../image/icon_pdf.svg";

const SectionWorkspace = () => {
  const navigate = useNavigate();
  const [state] = useContext(State);
  const [loading, setLoading] = useState(false);
  const [articlesState, setArticlesState] = useState();

  useEffect(() => {
    if (articlesState || state.showOverlay) return;

    const isIpqSaved = !!state.getPreferenceValue("prospectObjective");

    setArticlesState([
      {
        type: "perspectives",
        visible: getIsQuizCompleted("perspectives"),
      },
      {
        type: "priorities",
        visible: getIsQuizCompleted("priorities"),
      },
      {
        type: "milestones",
        visible: getIsQuizCompleted("milestones"),
      },
      {
        type: "investing",
        visible: isIpqSaved,
        status: isIpqSaved ? "started" : null,
      },
      {
        type: "generosity",
        visible: getIsQuizCompleted("generosity"),
      },
    ]);
  }, [state]);

  const guideInitialData = state.getPreferenceValue("guideInitialData");
  const prospectObjective = state.getPreferenceValue("prospectObjective");

  const isMarried = guideInitialData?.isMarried;

  const isIpqUser =
    // user manager view
    state.userManagerData?.onboardingQuestionnaireType === "IPQ" ||
    // org manager view
    state.userManagerData?.managedUserManagers?.find(
      it => it.email === state.userManagement?.linkedUserManagers?.[0]?.email
    )?.userManager?.onboardingQuestionnaireType === "IPQ";

  const getAssessmentLabel = () => {
    if (state.surveyLinkFor === "milestones") return "Milestones";
    else if (state.surveyLinkFor.includes("perspective")) return "Perspectives";
    else if (state.surveyLinkFor === "proposal") return "IPQ";
    else return "Priorities";
  };

  const getLinkValueMapKey = () => {
    if (state.surveyLinkFor === "milestones") return "milestonesQuestions";
    else if (state.surveyLinkFor.includes("perspective"))
      return state.perspectiveFor;
    else if (state.surveyLinkFor === "proposal") return "prospectObjective";
    else return state.prioritiesFor;
  };

  const getProposalData = () => {
    const productMap = state.getPreferenceValue("productMap");

    if (!productMap) return;

    const firstProposalHistoryList = productMap[Object.keys(productMap)[0]];

    return {
      ...firstProposalHistoryList.at(-1),
      ...state.productsList.find(
        it => it._id === firstProposalHistoryList.at(-1).productId
      ),
    };
  };

  const getRoute = () => {
    if (state.surveyLinkFor === "milestones") return "questionnaire-milestones";
    else if (state.surveyLinkFor.includes("perspective"))
      return "questionnaire-perspectives";
    else if (state.surveyLinkFor.includes("priorities"))
      return "questionnaire-priorities";
    else return "questionnaire-proposal";
  };

  const handleCopyLink = () => {
    const link = `${location.origin}/${getRoute()}?email=${state._id}&assessmentLabel=${getAssessmentLabel()}&valueMapKey=${getLinkValueMapKey()}&jwt=${state.jwt}`;
    navigator?.clipboard?.writeText(link);
    state.showSuccess("Link copied");
  };

  const handleIpqCreate = () => {
    state.setKeyValue("activeEmail", state._id);

    state.setKeyValue("surveyLinkFor", "proposal");
    state.setKeyValue("openModalSurveyLink", true);
  };

  const handleIpqView = () => {
    state.setKeyValue("openModalViewIpq", true);
  };

  const handlePerspectivesClose = () => {
    state.setKeyValue("openModalPerspectives", false);
    state.setKeyValue("perspectiveFor", null);
  };

  const handlePerspectivesComplete = perspectives => {
    state.setKeyValue("loading", true);

    state
      .setPreferenceValue(state.perspectiveFor, perspectives)
      ?.then(() => {
        state.showSuccess("Perspectives saved");
      })
      ?.catch(error => state.showError(error))
      ?.finally(() => state.setKeyValue("loading", false));
  };

  const handlePerspectivesViewResults = perspectiveType => {
    state.setKeyValue("viewPerspectiveIndividualResults", perspectiveType);
    state.setKeyValue("showButtonBackToDashboard", true);
  };

  const handlePrioritiesClose = () => {
    state.setKeyValue("openModalPriorities", false);
    state.setKeyValue("prioritiesFor", null);
  };

  const handlePrioritiesComplete = priorities => {
    state.setKeyValue("loading", true);

    state
      .setPreferenceValue(state.prioritiesFor, priorities)
      .then(() => {
        if (state.getPreferenceValue("prioritiesFamily")) {
          // reset family priorities, if saved before
          state
            .setPreferenceValue("prioritiesFamily", null)
            .then(() => {
              state.showSuccess("Priorities saved");
              state.setKeyValue("loading", false);
            })
            .catch(error => state.showError(error))
            .finally(() => state.setKeyValue("loading", false));
        } else {
          state.showSuccess("Priorities saved");
          state.setKeyValue("loading", false);
        }
      })
      .catch(error => {
        state.showError(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleProposalCreate = () => {
    if (!prospectObjective) {
      // quiz do not saved
      state.setKeyValue("openModalProposalGuideUser", true);
    } else if (prospectObjective?.secondQuizPartSkipped) {
      // user already passed first part of the quiz, open for him second part
      state.setKeyValue("showOverlay", true);
      state.setKeyValue("openModalProposalGuideUser", true);

      setTimeout(() => {
        // risk selection step
        navigate(location.pathname, { state: { stepIndex: 4 } });
        state.setKeyValue("showOverlay", false);
      }, 500);
    } else {
      // quiz passed, open proposal
      handleProposalView();
    }
  };

  const handleProposalView = () => {
    state.setKeyValue("showOverlay", true);
    navigate("/oneascent-proposal/goals");

    setTimeout(() => {
      state.setKeyValue("activeGoal", undefined);

      if (state.getPreferenceValue("productMap")) {
        // user have saved proposal(s), show Goals page
        state.setKeyValue("viewMode", "goals");
      } else {
        // no saved proposal, show Goal page
        state.setKeyValue("viewMode", "goal");
      }

      state.setKeyValue("showOverlay", false);
    }, 1000);
  };

  const handleSurveyLinkCancel = () => {
    state.setKeyValue("openModalSurveyLink", false);
    state.setKeyValue("surveyLinkFor", null);
  };

  const handleSurveyLinkContinue = () => {
    state.setKeyValue("openModalSurveyLink", false);

    if (state.surveyLinkFor === "milestones") {
      state.setKeyValue("viewMilestonesQuestionnaire", true);
      state.setKeyValue("showButtonBackToDashboard", true);
    } else if (state.surveyLinkFor?.includes("perspective")) {
      state.setKeyValue("openModalPerspectives", true);
    } else if (state.surveyLinkFor === "proposal") {
      state.setKeyValue("newIpqMode", true);
      state.setKeyValue("openModalProposalGuideUser", true);
    } else {
      state.setKeyValue("openModalPriorities", true);
    }
  };

  const handleSurveyLinkSend = ({ email }) => {
    if (!email) {
      state.showWarning("Please, fill 'email' field before sending");
      return;
    }

    setLoading(true);

    sendQuizLinkOnEmail({
      emailDestination: email,
      userId: state._id,
      userManagerId: state.userManagerData?._id,
      userManagerFullName: `${
        state.userManagerData?.personalInfo?.firstName ?? ""
      } ${state.userManagerData?.personalInfo?.lastName ?? ""}`,
      route: getRoute(),
      valueMapKey: getLinkValueMapKey(),
      domain: location.host,
      assessmentLabel: getAssessmentLabel(),
    })
      .then(() => {
        state.showSuccess(`Email sent to: ${email}`);
      })
      .catch(error => {
        state.showError(error);
        state.showError("Error occurred. Please, try later");
      })
      .finally(() => setLoading(false));
  };

  const handleArticleStart = type => {
    if (type === "milestones") {
      state.setKeyValue("surveyLinkFor", "milestones");
      state.setKeyValue("openModalSurveyLink", true);
      return;
    }

    setArticlesState(lastState =>
      lastState.map(it => {
        if (it.type === type) {
          return {
            ...it,
            status: "started",
            visible: true,
          };
        } else {
          return it;
        }
      })
    );
  };

  const isArticleVisible = type =>
    articlesState?.find(it => it.type === type)?.visible;

  const getIsQuizCompleted = type => {
    if (type === "perspectives") {
      const selectedPersonalityMy =
        state.getPreferenceValue("perspectiveMy")?.selectedPersonality;
      const selectedPersonalitySpouse =
        state.getPreferenceValue("perspectiveSpouse")?.selectedPersonality;

      return isMarried
        ? selectedPersonalityMy && selectedPersonalitySpouse
        : selectedPersonalityMy;
    } else if (type === "priorities") {
      const prioritiesMy = state.getPreferenceValue("prioritiesMy");
      const prioritiesFamily = state.getPreferenceValue("prioritiesFamily");

      return isMarried ? prioritiesFamily : prioritiesMy;
    } else if (type === "milestones") {
      return state.getPreferenceValue("milestonesQuestions");
    } else if (type === "investing") {
      return (
        state.getPreferenceValue("productMap") &&
        state.getPreferenceValue("prospectObjective")
      );
    } else if (type === "generosity") {
      return state.getPreferenceValue("generosityCompleted");
    }
  };

  const toggleArticleVisibility = type =>
    setArticlesState(lastState =>
      lastState.map(it => {
        if (it.type === type) {
          return {
            ...it,
            visible: !it.visible,
          };
        } else {
          return it;
        }
      })
    );

  return (
    <StylesContainer gap={30} vertical>
      <Flex className="header" gap={24} vertical>
        <h3>
          Welcome, {state.personalInfo?.firstName}
          {guideInitialData?.spouseFirstName
            ? ` and ${guideInitialData?.spouseFirstName}`
            : ""}
        </h3>
        <Flex gap={100} justify="space-between">
          <Flex gap={12} vertical>
            <p>
              Align is designed to transform your financial journey through a
              purpose-driven approach that brings clarity, strategy, and impact
              to every financial decision you make. Our unique process doesn't
              just manage your money—it empowers you to design Your Preferred
              Future. Many clients come to us feeling stuck on a path they
              didn't consciously choose. Together, we'll create a roadmap that
              aligns your resources with your deeply held values and
              aspirations, helping you shift from 'what will be' to 'what could
              be’.
            </p>
            <p>
              Our goal is to earn your trust as a lifetime advisor by delivering
              results that inspire you to share your experience with others who
              deserve the same opportunity to flourish.
            </p>
          </Flex>

          {isIpqUser && (
            <Button
              disabled={
                state.loading ||
                (!state.getPreferenceValue("guideInitialData") &&
                  !state.getPreferenceValue("perspectiveMy") &&
                  !state.getPreferenceValue("prioritiesMy") &&
                  !state.getPreferenceValue("milestonesQuestions"))
              }
              icon={<IconPdf />}
              onClick={() => state.setKeyValue("openModalCreateReport", true)}
              shape="round"
              style={{ width: 150 }}
              type="primary"
            >
              Create Report
            </Button>
          )}
        </Flex>
      </Flex>

      <Flex gap={16} vertical>
        <Flex gap={16}>
          {LIST_DASHBOARD_TABS_DATA.map(it => (
            <CardNav
              description={it.description}
              handleStart={handleArticleStart}
              handleVisibility={toggleArticleVisibility}
              isQuizCompleted={getIsQuizCompleted(it.type)}
              isStateStarted={
                articlesState?.find(articleData => it.type === articleData.type)
                  ?.status === "started"
              }
              isArticleVisible={
                articlesState?.find(articleData => it.type === articleData.type)
                  ?.visible
              }
              key={it.type}
              title={it.title}
              type={it.type}
            />
          ))}
        </Flex>

        <Flex gap={16} vertical>
          {isIpqUser && (
            <>
              {isArticleVisible("perspectives") && (
                <ArticlePerspectives
                  handlePerspectivesViewResults={handlePerspectivesViewResults}
                />
              )}
              {isArticleVisible("priorities") && <ArticlePriorities />}
              {isArticleVisible("milestones") && <ArticleMilestones />}
            </>
          )}

          {isArticleVisible("investing") && (
            <ArticleInvesting
              handleIpqCreate={handleIpqCreate}
              handleIpqView={handleIpqView}
              handleProposalCreate={handleProposalCreate}
              handleProposalView={handleProposalView}
              isIpqUser={isIpqUser}
              isProposalSaved={!!state.getPreferenceValue("productMap")}
              proposalData={getProposalData()}
              prospectObjective={state.getPreferenceValue("prospectObjective")}
            />
          )}

          {isIpqUser && isArticleVisible("generosity") && (
            <ArticleGenerosity
              handleComplete={() =>
                state.setPreferenceValue("generosityCompleted", true)
              }
              isCompleted={state.getPreferenceValue("generosityCompleted")}
            />
          )}
        </Flex>
      </Flex>

      <ModalCreateReport
        onCancel={() => state.setKeyValue("openModalCreateReport", false)}
        open={state.openModalCreateReport}
      />
      <ModalPerspectives
        handleComplete={handlePerspectivesComplete}
        onCancel={handlePerspectivesClose}
        open={state.openModalPerspectives}
      />
      <ModalPriorities
        handleComplete={handlePrioritiesComplete}
        initialState={
          !state.reTakePrioritiesQuizMode &&
          state.getPreferenceValue(state.prioritiesFor)
        }
        onCancel={handlePrioritiesClose}
        open={state.openModalPriorities}
        viewResultsMode={state.viewResultsPrioritiesQuizMode}
      />
      <ModalProposalGuideUser
        open={state.openModalProposalGuideUser}
        handleClose={() =>
          state.setKeyValue("openModalProposalGuideUser", false)
        }
      />
      <ModalSendSurveyLink
        email={
          state.surveyLinkFor?.includes("Spouse")
            ? state.getPreferenceValue("guideInitialData")?.spouseEmail
            : state._id
        }
        handleCopyLink={handleCopyLink}
        loading={loading}
        open={state.openModalSurveyLink}
        onCancel={handleSurveyLinkCancel}
        onContinue={handleSurveyLinkContinue}
        onSend={handleSurveyLinkSend}
      />
      <ModalViewIpq
        activeUserData={{
          ...state.personalInfo,
          ...state.preferences,
        }}
        onCancel={() => state.setKeyValue("openModalViewIpq", false)}
        open={state.openModalViewIpq}
      />
    </StylesContainer>
  );
};

export default SectionWorkspace;
