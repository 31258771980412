import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Flex, Image, theme } from "antd";
import ChartProjection from "../../../pages/PageLevelProposal/component/ChartProjection";
import ReportHeader from "../../component/ReportHeader";

import StylesContainer from "./style";

import { RISK_TYPES_LEVEL } from "../../../constants";

import { getLiveAssessmentV2 } from "../../../request";
import {
  getCurrencyValue,
  getPercentValue,
} from "../../../../../utils/helper/general";

import checkmark_checked from "./image/checkmark_checked.svg";
import checkmark_unchecked from "./image/checkmark_unchecked.svg";
import { ReactComponent as CheckmarkGrowth } from "./image/checkmark_growth.svg";

const ReportPageProposalGrowth = ({
  personaName,
  productsList,
  proposalData,
  setLoading,
}) => {
  const { token } = theme.useToken();
  const [productsLiveAssessment, setProductsLiveAssessment] = useState();

  useEffect(() => {
    if (!proposalData) return;

    setLoading(true);

    getLiveAssessmentV2({
      ...proposalData,
      advisorFee: getPercentValue(proposalData.advisorFee),
      birthYear: proposalData.currentAge ? undefined : proposalData.birthYear,
      organization: proposalData.organization,
      productId: proposalData.productId,
      withdrawalLevel: getPercentValue(proposalData.withdrawalLevel ?? 0),
    }).then(liveAssessment => {
      setProductsLiveAssessment([
        {
          ...liveAssessment,
          productId: proposalData.productId,
        },
      ]);

      // leave time for charts rendering
      setTimeout(() => setLoading(false), 1000);
    });
  }, [proposalData]);

  const Checkmarks = () => (
    <Flex gap={14} vertical>
      {RISK_TYPES_LEVEL.map(({ label }) => (
        <Flex align="center" gap={10} key={label}>
          <Flex justify="center" style={{ width: 41 }}>
            <Image
              preview={false}
              src={
                proposalData?.riskTolerance?.toLowerCase() ===
                label.toLowerCase()
                  ? checkmark_checked
                  : checkmark_unchecked
              }
            />
          </Flex>
          <span
            className={
              proposalData?.riskTolerance?.toLowerCase() === label.toLowerCase()
                ? "risk-label active"
                : "risk-label"
            }
          >
            {label}
          </span>
        </Flex>
      ))}
    </Flex>
  );

  return (
    <StylesContainer id="reportPageProposalGrowth">
      <Flex className="page-container" vertical>
        <ReportHeader personaName={personaName} />

        <main>
          <h3>What we talked about</h3>
          <p style={{ width: 783 }}>
            Setting clear financial goals acts as a roadmap for your financial
            journey, ensuring that every decision you make is purposeful and
            directed toward your desired outcomes. By aligning investment
            products with your personal aspirations, it becomes possible to
            regularly assess whether the chosen strategy is effectively guiding
            you toward success and remains advantageous for achieving your
            financial objectives.
          </p>

          <Flex justify="space-between" style={{ marginTop: 70 }}>
            <Flex flex="0 658px" gap={24} vertical>
              <Flex justify="space-between">
                <h3>{proposalData?.productName}</h3>
                <CheckmarkGrowth />
              </Flex>
              <ChartProjection
                productsList={productsList}
                proposalData={proposalData}
                productsLiveAssessment={productsLiveAssessment}
              />
            </Flex>

            <Flex align="center" gap={24} vertical>
              <h3>Portfolio Mix</h3>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    height: 300,
                    spacingTop: 0,
                    type: "pie",
                    width: 300,
                  },
                  title: {
                    text: null,
                  },
                  credits: {
                    enabled: false,
                  },
                  plotOptions: {
                    pie: {
                      size: 158,
                      innerSize: "60%",
                      dataLabels: {
                        enabled: false,
                      },
                      showInLegend: true,
                      center: ["50%", "50%"],
                      borderColor: "#ffffff",
                      borderWidth: 2,
                    },
                    series: {
                      animation: false,
                    },
                  },
                  legend: {
                    enabled: true,
                    layout: "vertical",
                    align: "center",
                    verticalAlign: "bottom",
                    borderWidth: 0,
                    labelFormatter: function () {
                      return this.y + "% " + this.name;
                    },
                    itemStyle: {
                      fontSize: 14,
                      textOverflow: null,
                    },
                  },
                  exporting: {
                    buttons: {
                      contextButton: {
                        enabled: false,
                      },
                    },
                  },
                  colors: token.chart_colors,
                  series: [
                    {
                      colorByPoint: true,
                      data: productsList
                        .find(
                          product => product.name === proposalData?.productName
                        )
                        ?.assets?.map(it => ({
                          name: it.assetName,
                          y: it.investmentPercent,
                        })),
                      name: "Selected",
                      size: "100%",
                    },
                  ],
                }}
              />
            </Flex>
          </Flex>

          <Flex gap={10} style={{ marginTop: 53 }} vertical>
            <h3>Your Selections</h3>

            <Flex className="selections-container" gap={126}>
              <div className="value-container">
                <span className="label">Investment Objective</span>
                <p className="value" style={{ textTransform: "capitalize" }}>
                  {proposalData?.investmentObjective}
                </p>
              </div>

              <div className="value-container">
                <span className="label">
                  {token.proposal_copy_investment_amount}
                </span>
                <p className="value">
                  {getCurrencyValue(proposalData?.investmentAmount)}
                </p>
              </div>

              <div className="value-container">
                <span className="label">Risk Tolerance</span>
                <p className="value" style={{ textTransform: "capitalize" }}>
                  {proposalData?.riskTolerance}
                </p>
              </div>

              <div className="value-container">
                <span className="label">Length of Investment</span>
                <p className="value">
                  {proposalData?.investmentDuration} year(s)
                </p>
              </div>
            </Flex>
          </Flex>
          <section className="section-about-risk-tolerance">
            <Flex flex="0 672px" gap={9} vertical>
              <div>
                <h3 style={{ fontWeight: 400 }}>About your risk tolerance: </h3>
                <h3 style={{ textTransform: "capitalize" }}>
                  {proposalData?.riskTolerance}
                </h3>
              </div>
              <p>
                Investors willing to balance capital appreciation and capital
                preservation and who are willing to accept some volatility and
                potential loss.
              </p>
            </Flex>

            <Checkmarks />
          </section>

          <section className="section-planning-corner">
            <h5>Planning Corner</h5>

            <h3>Diversification a "spread the risk" concept</h3>
            <p>
              Diversification spreads investments across different areas, like
              stocks, bonds, and real estate. This helps reduce risk, as gains
              in one area can offset losses in another, protecting overall
              returns.
            </p>
          </section>
        </main>
      </Flex>
    </StylesContainer>
  );
};

export default ReportPageProposalGrowth;
