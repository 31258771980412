import { Button, Flex, Checkbox } from "antd";

import StyledContainer from "./style";

const ArticleGenerosity = ({ handleComplete, isCompleted }) => (
  <StyledContainer>
    <article className="container-article">
      <Flex className="header" gap={8} vertical>
        <h4>Generosity</h4>
        <p>
          Generosity extends your values beyond personal benefit. Your giving
          practices reflect your priorities and create lasting impact in areas
          you value most.
        </p>
      </Flex>

      <Flex className="container-description" gap={32}>
        <Button
          onClick={() =>
            window.open("https://onegive.oneascent.com/login", "_blank")
          }
          shape="round"
          style={{ background: "#209F00", width: 180 }}
          type="primary"
        >
          Visit OneGive
        </Button>

        <Flex
          align="center"
          gap={8}
          onClick={() => !isCompleted && handleComplete()}
          style={{
            background: "#FFF",
            borderRadius: 8,
            cursor: !isCompleted && "pointer",
            padding: "4px 8px",
          }}
        >
          <Checkbox checked={isCompleted} disabled={isCompleted} />
          Mark as Completed
        </Flex>
      </Flex>
    </article>
  </StyledContainer>
);

export default ArticleGenerosity;
