export const LIST_DASHBOARD_TABS_DATA = [
  {
    description:
      "We’re all uniquely made. Your personal lens shapes how you view, interpret, and interact with money.",
    title: "Perspectives",
    type: "perspectives",
  },
  {
    description:
      "Priorities are the specific things you value most highly and choose to focus your time, energy, and resources on.",
    title: "Priorities",
    type: "priorities",
  },
  {
    description:
      "Milestones mark meaningful financial progress. They show how far you’ve come and what’s next.",
    title: "Milestones",
    type: "milestones",
  },
  {
    description:
      "Investing expresses your values through capital allocation. Your Portfolio reveals whether your financial strategies match your deepest convictions. ",
    title: "Investing",
    type: "investing",
  },
  {
    description:
      "Generosity extends your values beyond personal benefit. Your giving practices reflect your priorities and create lasting impact in areas you value most. ",
    title: "Generosity",
    type: "generosity",
  },
];
