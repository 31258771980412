import styled from "styled-components";
import { Flex } from "antd";

const StyledContainer = styled(Flex)`
  background: linear-gradient(180deg, #2473c7 0%, #00346b 100%);
  border-radius: 12px;
  color: #fff;
  min-height: 270px;
  padding: 25px;
  width: 210px;

  &.state-completed {
    background: linear-gradient(
      180deg,
      rgba(113, 181, 254, 0.2) 0%,
      rgba(68, 146, 229, 0.2) 100%
    );
    color: #142e52;

    p {
      font-size: 16px;
    }
  }

  h4 {
    font-size: 22px;
    margin: unset;
    text-transform: capitalize;
  }

  p {
    margin: unset;
  }

  .ant-btn.ant-btn-round.ant-btn {
    background: #fff;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.45);
    color: #15294c;

    &:disabled {
      background: #cecece;
      color: #a2a2a2;
    }
  }
`;

export default StyledContainer;
