import { useRef } from "react";
import dayjs from "dayjs";
import HighchartsReact from "highcharts-react-official";
import HighStock from "highcharts/highstock";
import { Flex } from "antd";
import ChartSkeleton from "../../../../../../atom/ChartSkeleton";

import StylesContainer from "./style.js";

import { getPercentValue } from "../../../../../../utils/helper/general";

const SectionRisk = ({ etfData, loading, product }) => {
  const chartRef = useRef(null);

  return (
    <StylesContainer>
      <ChartSkeleton loading={loading}>
        <HighchartsReact
          ref={chartRef}
          constructorType="stockChart"
          highcharts={HighStock}
          options={{
            credits: {
              enabled: false,
            },
            series: [
              {
                data: etfData
                  ? Object.keys(etfData["Drawdown Series"]).map(date => [
                      new Date(date).getTime(),
                      etfData["Drawdown Series"][date],
                    ])
                  : [],
                name: product?.ticker ?? "SPY",
                type: "area",
                color: "transparent",
                zones: [
                  { value: 0, color: "rgba(255, 200, 200, 0.8)" },
                  { value: -0.1, color: "rgba(255, 200, 200, 0.8)" },
                  { value: -0.2, color: "rgba(255, 150, 150, 0.8)" },
                  { value: -0.9, color: "rgba(255, 100, 100, 0.8)" },
                ],
              },
            ],
          }}
        />

        <h4>Top 3 Drawdowns</h4>
        <Flex gap={16}>
          {etfData &&
            etfData["Top Drawdowns"].slice(0, 3).map((it, index) => (
              <div className="value-container" key={index}>
                <span>{dayjs(it.bottom_date).format("MM/DD/YYYY")}</span>
                <p>{getPercentValue(Math.round(it.drawdown * 10000) / 100)}</p>
              </div>
            ))}
        </Flex>
      </ChartSkeleton>
    </StylesContainer>
  );
};

export default SectionRisk;
