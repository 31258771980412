import styled from "styled-components";

const StyledContainer = styled.div`
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #fff;
  }

  .ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: #209f00;
  }

  .ant-table-wrapper .ant-table.ant-table-small .ant-table-title,
  .ant-table-wrapper .ant-table.ant-table-small .ant-table-footer,
  .ant-table-wrapper .ant-table.ant-table-small .ant-table-cell,
  .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > th,
  .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table-wrapper .ant-table.ant-table-small tfoot > tr > th,
  .ant-table-wrapper .ant-table.ant-table-small tfoot > tr > td {
    padding: 3px;
  }

  .ant-table-wrapper {
    width: 305px;
  }

  .ant-table-wrapper .ant-table {
    background: transparent;
    color: #0a0a0a;
    font-weight: 500;
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: transparent;
    color: #737373;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .cell-category-name {
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    padding: 2px;
    text-align: center;
    width: 140px;
  }
`;

export default StyledContainer;
