import { Button, Flex, Switch, ConfigProvider } from "antd";

import StyledContainer from "./styles";

import { ReactComponent as Complete } from "./image/icon_complete.svg";

const CardNav = ({
  description,
  handleStart,
  handleVisibility,
  isArticleVisible,
  isQuizCompleted,
  isStateStarted,
  title,
  type,
}) => {
  return isQuizCompleted ? (
    <ConfigProvider
      theme={{
        components: {
          Switch: {
            colorPrimary: "#019A00",
            colorPrimaryHover: "#019A00",
          },
        },
      }}
    >
      <StyledContainer
        align="center"
        className="state-completed"
        justify="space-between"
        vertical
      >
        <h4>{type}</h4>

        <Flex align="center" gap={10} vertical>
          <Complete />
          <p>Completed</p>
        </Flex>

        <Flex
          align="center"
          gap={8}
          onClick={() => handleVisibility(type)}
          style={{ color: "#052C2B", cursor: "pointer", fontWeight: 500 }}
        >
          <Switch value={isArticleVisible} /> Show
        </Flex>
      </StyledContainer>
    </ConfigProvider>
  ) : (
    <StyledContainer justify="space-between" vertical>
      <Flex gap={16} vertical>
        <h4>{title}</h4>
        <p>{description}</p>
      </Flex>

      <Button
        block
        disabled={isStateStarted}
        onClick={() => handleStart(type)}
        shape="round"
        type="text"
      >
        {isStateStarted ? "Started..." : "Get Started"}
      </Button>
    </StyledContainer>
  );
};

export default CardNav;
