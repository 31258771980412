import { useContext, useState } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context";
import { Button, Flex, Input, theme } from "antd";
import MenuActions from "../../../../../../atom/MenuActions";
import ModalArchiveManagedUser from "../../../../../../molecule/ModalArchiveManagedUser";
import ModalConvertClient from "../ModalConvertClient";
import ModalSendSurveyLink from "../../../../../oneascent/page/PageProposalDashboard/components/ModalSendSurveyLink";
import ModalVbi from "../../../../../oneascent/component/ModalVbi";

import StyledTable from "./style";

import {
  archiveLead,
  openProposal,
} from "../../../../../../utils/request/manager";
import {
  getManagedUsers,
  handleCreateProposal,
  isAdminView,
  isManagerTypeIpq,
  isOrganizationOneascent,
} from "../../../../../../utils/helper/specialized";
import { getCurrencyValue } from "../../../../../../utils/helper/general";
import {
  downloadVbiFile,
  getQuizLink,
  sendQuizLinkOnEmail,
} from "../../../../../oneascent/request";
import { getManagedUserAssets } from "../../../../../oneascent/utils";

const TableLevelAdvisorClients = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [searchedText, setSearchedText] = useState("");
  useState(false);
  const [isConvertModalOpened, setIsConvertModalOpened] = useState(false);
  const [clientJwt, setClientJwt] = useState();

  const handleActionEditClient = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("openModalLevelClientDetails", true);
  };

  const handleActionNewIpq = email => {
    state.setKeyValue("loading", true);
    state.setKeyValue("activeEmail", email);

    getQuizLink({
      userId: email,
      userManagerId: state._id,
      userManagerFullName: "",
      route: location.origin,
      valueMapKey: "",
      domain: "",
    })
      .then(link => {
        setClientJwt(link.split("jwt=")[1]);
        state.setKeyValue("openModalSurveyLink", true);
      })
      .catch(state.showError)
      .finally(() => state.setKeyValue("loading", false));
  };

  const handleActionVbi = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("open_modal_vbi", true);
  };

  const handleActionViewIpq = email => {
    state.setKeyValue("activeEmail", email);
    state.setKeyValue("openModalViewIpq", true);
  };

  const handleActionConvertClient = email => {
    state.setKeyValue("activeEmail", email);
    setIsConvertModalOpened(true);
  };

  const handleActionArchiveClient = () => {
    state.closeModal("archiveManagedUserModal");
    state.setKeyValue("loading", true);

    archiveLead({
      email: state.activeEmail,
      userManagerEmail: state._id,
    })
      .then(() =>
        state.setAdminData().then(() => {
          state.setKeyValue("loading", false);
          state.setKeyValue("activeEmail", null);
          state.showSuccess(`Client ${state.activeEmail} archived`);
        })
      )
      .catch(error => {
        state.showError(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleActionArchiveClientActionClick = email => {
    state.setKeyValue("activeEmail", email);
    state.openModal("archiveManagedUserModal");
  };

  const handleCopyLink = () => {
    const link = `${location.origin}/questionnaire-proposal?email=${state.activeEmail}&assessmentLabel="IPQ"&valueMapKey=prospectObjective&jwt=${clientJwt}`;
    navigator?.clipboard?.writeText(link);
    state.showSuccess("Link copied");
  };

  const handleSurveyLinkCancel = () => {
    state.setKeyValue("openModalSurveyLink", false);
  };

  const handleSurveyLinkContinue = () => {
    state.setKeyValue("openModalSurveyLink", false);
    state.setKeyValue("newIpqMode", true);
    state.setKeyValue("openModalProposalGuide", true);
  };

  const handleSurveyLinkSend = ({ email }) => {
    if (!email) {
      state.showWarning("Please, fill 'email' field before sending");
      return;
    }

    state.setKeyValue("loading", true);

    sendQuizLinkOnEmail({
      emailDestination: email,
      userId: state.activeEmail,
      userManagerId: state._id,
      userManagerFullName: `${
        state.personalInfo?.firstName ?? ""
      } ${state.personalInfo?.lastName ?? ""}`,
      route: "questionnaire-proposal",
      valueMapKey: "prospectObjective",
      domain: location.host,
      assessmentLabel: "IPQ",
    })
      .then(() => {
        state.showSuccess(`Email sent to: ${email}`);
      })
      .catch(state.showError)
      .finally(() => state.setKeyValue("loading", false));
  };

  const getTableData = () =>
    getManagedUsers(state)?.map(it => ({
      ...it,
      assets: getManagedUserAssets(it),
      clientName: it.lastName + ", " + it.firstName,
      key: it.email,
      proposalSaved: it.proposalSavedDate ?? it.valueMap?.productMap,
    }));

  const columns = [
    {
      dataIndex: "clientName",
      filteredValue: [searchedText],
      onFilter: (value, record) =>
        String(record.clientName).toLowerCase().includes(value.toLowerCase()) ||
        String(record.email).toLowerCase().includes(value.toLowerCase()),
      render: (name, restValues) =>
        restValues.proposalSaved ||
        isOrganizationOneascent(state.organization?.name) ? (
          <a
            onClick={() =>
              openProposal({
                userEmail: restValues.email,
                organization: state.organization?.name,
              })
            }
          >
            {name}
          </a>
        ) : (
          <b>{name}</b>
        ),
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
      title: "Client Name",
    },
    {
      dataIndex: "email",
      render: value => <a href={`mailto:${value}`}>{value}</a>,
      sorter: (a, b) => a.email.localeCompare(b.email),
      title: "Email",
    },
    {
      align: "left",
      hidden: !isManagerTypeIpq(state.onboardingQuestionnaireType),
      render: allValues =>
        allValues.valueMap?.prospectObjective && (
          <a onClick={() => handleActionViewIpq(allValues.email)}>View</a>
        ),
      sorter: (a, b) =>
        !!a.valueMap?.prospectObjective - !!b.valueMap?.prospectObjective,
      title: "IPQ",
    },
    {
      render: allValues => {
        const vbi_status = allValues.vbi?.statusAdvisorView;

        if (vbi_status === "View")
          return (
            <a
              onClick={() =>
                downloadVbiFile({
                  advisor: state._id,
                  client: allValues.email,
                })
              }
            >
              View
            </a>
          );

        return vbi_status;
      },
      sorter: (a, b) =>
        (a.vbi?.statusAdvisorView ?? "")?.localeCompare(
          b.vbi?.statusAdvisorView ?? ""
        ),
      title: "VBI Audit",
    },
    {
      align: "right",
      className: "assets-column",
      dataIndex: "assets",
      render: value => getCurrencyValue(value),
      sorter: (a, b) => a.assets - b.assets,
      title: "Assets",
    },
    {
      align: "right",
      render: allValues =>
        allValues.proposalSaved ? (
          dayjs(allValues.updateDateTime?.$date).format("MM/DD/YYYY")
        ) : (
          <Flex justify="end">
            <Button
              shape="round"
              size="small"
              style={{
                borderColor: token.color_secondary_button,
                color: token.color_secondary_button,
              }}
              onClick={() => handleCreateProposal(allValues.email, state)}
              disabled={isAdminView()}
            >
              Create Proposal
            </Button>
          </Flex>
        ),
      sorter: (a, b) =>
        dayjs(a.updateDateTime?.$date) - dayjs(b.updateDateTime?.$date),
      title: "Last Accessed",
    },
    {
      className: "actions-menu-table-cell",
      render: allValues => (
        <MenuActions
          items={[
            {
              key: "editClient",
              label: "Edit Client",
              onClick: () => handleActionEditClient(allValues.email),
            },
            {
              key: "convertClient",
              label: isOrganizationOneascent(state.organization?.name)
                ? "Mark as Prospect"
                : "Move to Lead",
              onClick: () => handleActionConvertClient(allValues.email),
            },
            {
              hidden: !isOrganizationOneascent(state.organization?.name),
              key: "vbi",
              label: "VBI Audit",
              onClick: () => handleActionVbi(allValues.email),
            },
            {
              hidden: !isManagerTypeIpq(state.onboardingQuestionnaireType),
              key: "newIpq",
              label: "New IPQ",
              onClick: () => handleActionNewIpq(allValues.email),
            },
            {
              type: "divider",
            },
            {
              key: "archiveClient",
              label: <span style={{ color: "red" }}>Archive</span>,
              onClick: () =>
                handleActionArchiveClientActionClick(allValues.email),
            },
          ].filter(it => !it.hidden)}
        />
      ),
      title: "",
      width: 40,
    },
  ].filter(it => !it.hidden);

  return (
    <>
      <Input
        style={{
          maxWidth: 290,
          height: 37,
          marginBottom: 16,
          borderRadius: 25,
        }}
        placeholder="Search Clients..."
        onChange={event => setSearchedText(event.target.value)}
      />
      <StyledTable
        columns={columns}
        dataSource={getTableData()}
        loading={state.loading}
        pagination={false}
        rowHoverable={false}
      />

      <ModalArchiveManagedUser
        loading={state.loading}
        onCancel={() => state.closeModal("archiveManagedUserModal")}
        onConfirm={handleActionArchiveClient}
        open={state.archiveManagedUserModal}
      />
      <ModalConvertClient
        email={state.activeEmail}
        handleClose={() => setIsConvertModalOpened(false)}
        open={isConvertModalOpened}
      />
      <ModalSendSurveyLink
        email={state.activeEmail}
        handleCopyLink={handleCopyLink}
        loading={state.loading}
        open={state.openModalSurveyLink}
        onCancel={handleSurveyLinkCancel}
        onContinue={handleSurveyLinkContinue}
        onSend={handleSurveyLinkSend}
      />
      <ModalVbi
        onCancel={() => state.setKeyValue("open_modal_vbi", false)}
        open={state.open_modal_vbi}
      />
    </>
  );
};

export default TableLevelAdvisorClients;
