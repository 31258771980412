import { getListUseOfMoney } from "../constant";

export const getChartConfigPlanning = ({
  height = 400,
  hideLegend,
  labelAccentValues,
  series = [],
  sizeSmall,
  width = 320,
}) => ({
  chart: {
    backgroundColor: "transparent",
    height,
    polar: true,
    spacingRight: 0,
    spacingTop: -40,
    type: "line",
    width,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: !hideLegend,
    align: "right",
    floating: true,
    itemStyle: {
      color: "#000",
      fontFamily: "Inter, sans-serif",
      fontSize: 12,
      fontWeight: 500,
    },
    layout: "horizontal",
    verticalAlign: "bottom",
  },
  pane: {
    size: "60%",
  },
  plotOptions: {
    marker: {
      enabled: false,
    },
  },
  series: series,
  title: {
    text: "",
  },
  tooltip: {
    outside: true,
    pointFormat:
      '<span style="color:{series.color}">{series.name}: <b>' +
      "{point.y:,.0f}</b><br/>",
    shared: true,
  },
  xAxis: {
    categories: ["Spending", "Giving", "Saving", "Investing"],
    gridLineColor: "#B1B1B1",
    labels: {
      formatter: function () {
        return labelAccentValues?.find(
          it => (it + "").toLowerCase() === (this.value + "").toLowerCase()
        )
          ? `<span
              style="
                background: ${
                  getListUseOfMoney().find(
                    it => it.type.toLowerCase() === this.value.toLowerCase()
                  )?.accentColor
                };
                border-radius: 12px;
                color: #fff;
                left: ${this.value === "Investing" ? "20px" : null};
                padding: 10px;
                position: relative;
                right: ${this.value === "Giving" ? "20px" : null};
              ">${this.value}</span>`
          : `<span style="
              position: relative;
              left: ${
                this.value === "Investing"
                  ? sizeSmall
                    ? "30px"
                    : "10px"
                  : null
              };
              right: ${
                this.value === "Giving" ? (sizeSmall ? "30px" : "10px") : null
              };
              top: ${
                sizeSmall &&
                (this.value === "Investing" || this.value === "Giving")
                  ? "15px"
                  : null
              }
            ">${this.value}</span>`;
      },
      style: {
        color: "#404040",
        fontSize: 16,
      },
      useHTML: true,
    },
    lineWidth: 0,
    tickmarkPlacement: "on",
  },
  yAxis: {
    gridLineInterpolation: "polygon",
    labels: {
      enabled: false,
    },
    lineWidth: 0,
    min: 0,
    max: 100,
  },
});
