import styled from "styled-components";
import { Flex } from "antd";

const StylesContainer = styled(Flex)`
  width: 1114px;
  margin: 0 auto;

  .header {
    h3 {
      color: #114984;
      font-size: 21px;
      margin: unset;
    }

    p {
      color: #000;
      font-size: 14px;
      line-height: 140%;
      margin: unset;
    }
  }

  article {
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: linear-gradient(180deg, #2473c7 0%, #00346b 100%);
    border-radius: 12px;
    line-height: normal;
    padding: 32px;

    .header {
      h4 {
        color: #fff;
        font-size: 22px;
        font-weight: 400;
        margin: unset;
      }

      p {
        color: #fff;
      }
    }

    .container-description {
      background: rgba(255, 255, 255, 0.9);
      border-radius: 12px;
      padding: 24px;
      width: 100%;

      .description {
        color: #000;
        width: 487px;

        h5 {
          font-size: 17px;
          line-height: 120%;
          margin: unset;
        }

        p {
          margin: unset;
        }
      }

      .container-action-buttons {
        width: 328px;
      }
    }

    .perspective-step-marker {
      align-items: center;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 12px;
      color: #12457a;
      display: flex;
      font-size: 12px;
      font-weight: 500;
      height: 17px;
      justify-content: center;
      width: 26px;
    }
  }
`;

export default StylesContainer;
