import styled from "styled-components";

import slider_background from "./image/slider_background.svg";
import slider_button_main from "./image/slider_button_main.svg";
import slider_button_secondary from "./image/slider_button_secondary.svg";
import slider_extra from "./image/slider_button_extra.svg";

const StyledContainer = styled.div`
  overflow: hidden;

  .label-risk-type {
    font-size: 26px;
    margin: unset;
    text-transform: capitalize;
  }

  .ant-slider-horizontal {
    margin: 0 0 46px;
  }

  .ant-slider .ant-slider-track {
    background: unset;
    height: 11px;
    cursor: default;
  }

  .ant-slider .ant-slider-rail {
    background: url("${slider_background}") center;
    background-size: cover;
    height: 11px;
    cursor: default;

    &::before {
      content: "";
      border-left: 2px solid #8c8c8c;
      height: 26px;
      left: 0;
      position: absolute;
      top: -7px;
    }

    &::after {
      content: "";
      border-left: 2px solid #8c8c8c;
      height: 26px;
      position: absolute;
      right: 0;
      top: -7px;
    }
  }

  .ant-slider .ant-slider-handle {
    box-shadow: unset;
    z-index: 1;

    &::before {
      background: url("${slider_button_main}") center no-repeat;
      background-size: cover;
      height: 56px;
      left: -25px;
      top: -17px;
      width: 56px;
      z-index: 2;
    }

    &:not(.ant-slider-handle-2)::after {
      background: url("${slider_extra}") center no-repeat;
      background-size: cover;
      border-radius: unset;
      box-shadow: unset;
      height: 11px;
      left: -70px;
      top: 3px;
      width: 134px;
      z-index: 1;
    }

    &.ant-slider-handle-2 {
      &::before {
        background: url("${slider_button_secondary}") center no-repeat;
      }

      &::after {
        display: none;
      }
    }
  }

  .ant-slider.small .ant-slider-handle::before {
    height: 32px;
    left: -12px;
    top: -7px;
    width: 32px;
  }
`;

export default StyledContainer;
