import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
import { Flex, theme } from "antd";
import ChartIncomeWithTypesLevel from "../../../pages/PageLevelProposal/component/ChartIncomeWithTypesLevel";
import ChartProjection from "../../../pages/PageLevelProposal/component/ChartProjection";
import ReportHeader from "../../component/ReportHeader";

import StylesContainer from "./style";

import {
  WITHDRAWAL_SEARCH_PARAMETER,
  WITHDRAWAL_START_ON_CHART_GAP,
} from "../../../../proposal/constant";

import { getLiveAssessmentV2 } from "../../../request";
import {
  cleanPercentValue,
  getCurrencyValue,
  getPercentValue,
} from "../../../../../utils/helper/general";

import { ReactComponent as IncomeChartLegend } from "./image/income_chart_legend.svg";
import { ReactComponent as CheckmarkIncome } from "./image/checkmark_income.svg";

const ReportPageProposalIncome = ({
  personaName,
  productsList,
  proposalData,
  setLoading,
}) => {
  const { token } = theme.useToken();
  const [productsLiveAssessment, setProductsLiveAssessment] = useState();

  useEffect(() => {
    if (!proposalData) return;

    setLoading(true);

    getLiveAssessmentV2({
      ...proposalData,
      advisorFee: getPercentValue(proposalData.advisorFee),
      birthYear: proposalData.currentAge ? undefined : proposalData.birthYear,
      organization: proposalData.organization,
      productId: proposalData.productId,
      withdrawalLevel: getPercentValue(proposalData.withdrawalLevel ?? 0),
    }).then(liveAssessment => {
      setProductsLiveAssessment([
        {
          ...liveAssessment,
          productId: proposalData.productId,
        },
      ]);

      // leave time for charts rendering
      setTimeout(() => setLoading(false), 4000);
    });
  }, [proposalData]);

  const getAnnualIncomeTargetValue = () => {
    if (proposalData?.withdrawalLevel === undefined) return;

    let incomeTargetValue = 0;

    if (isNaN(proposalData.withdrawalLevel)) {
      // percent amount
      const yearCurrent = +dayjs().format("YYYY");
      const yearWithdrawalStart =
        yearCurrent +
        proposalData.yearToStartWithdrawals +
        WITHDRAWAL_START_ON_CHART_GAP;
      const withdrawalPercent =
        cleanPercentValue(proposalData.withdrawalLevel) / 100;

      incomeTargetValue =
        productsLiveAssessment
          // to be sure, that used selected product data in case of compare mode
          ?.find(it => it.productId === proposalData.productId)
          ?.main.find(
            it =>
              it.Date.includes(yearWithdrawalStart + "") &&
              it.Date.includes(WITHDRAWAL_SEARCH_PARAMETER) // first month value
          )?.Value * withdrawalPercent;
    } else {
      // dollar amount
      incomeTargetValue = +proposalData.withdrawalLevel;
    }

    return Math.round(incomeTargetValue);
  };

  return (
    <StylesContainer id="reportPageProposalIncome">
      <Flex className="page-container" vertical>
        <ReportHeader personaName={personaName} />

        <main>
          <h3>What we talked about</h3>
          <p style={{ marginBottom: 50, width: 783 }}>
            Setting clear financial goals acts as a roadmap for your financial
            journey, ensuring that every decision you make is purposeful and
            directed toward your desired outcomes. By aligning investment
            products with your personal aspirations, it becomes possible to
            regularly assess whether the chosen strategy is effectively guiding
            you toward success and remains advantageous for achieving your
            financial objectives.
          </p>

          <Flex justify="space-between">
            <Flex flex="0 658px" gap={24} vertical>
              <Flex justify="space-between">
                <h3>{proposalData?.productName}</h3>
                <CheckmarkIncome />
              </Flex>
              <ChartProjection
                productsList={productsList}
                proposalData={proposalData}
                productsLiveAssessment={productsLiveAssessment}
              />
            </Flex>

            <Flex align="center" gap={24} vertical>
              <h3>Portfolio Mix</h3>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    backgroundColor: "transparent",
                    height: 300,
                    spacingTop: 0,
                    type: "pie",
                    width: 300,
                  },
                  title: {
                    text: null,
                  },
                  credits: {
                    enabled: false,
                  },
                  plotOptions: {
                    pie: {
                      size: 158,
                      innerSize: "60%",
                      dataLabels: {
                        enabled: false,
                      },
                      showInLegend: true,
                      center: ["50%", "50%"],
                      borderColor: "#ffffff",
                      borderWidth: 2,
                    },
                    series: {
                      animation: false,
                    },
                  },
                  legend: {
                    enabled: true,
                    layout: "vertical",
                    align: "center",
                    verticalAlign: "bottom",
                    borderWidth: 0,
                    labelFormatter: function () {
                      return this.y + "% " + this.name;
                    },
                    itemStyle: {
                      fontSize: 14,
                      textOverflow: null,
                    },
                  },
                  exporting: {
                    buttons: {
                      contextButton: {
                        enabled: false,
                      },
                    },
                  },
                  colors: token.chart_colors,
                  series: [
                    {
                      colorByPoint: true,
                      data: productsList
                        .find(
                          product => product.name === proposalData?.productName
                        )
                        ?.assets?.map(it => ({
                          name: it.assetName,
                          y: it.investmentPercent,
                        })),
                      name: "Selected",
                      size: "100%",
                    },
                  ],
                }}
              />
            </Flex>
          </Flex>

          <h3 style={{ marginBottom: 10 }}>Your Selections</h3>
          <Flex
            className="selections-container"
            gap={126}
            style={{ marginBottom: 50 }}
          >
            <div className="value-container">
              <span className="label">Investment Objective</span>
              <p className="value" style={{ textTransform: "capitalize" }}>
                {proposalData?.investmentObjective}
              </p>
            </div>
            <div className="value-container">
              <span className="label">Length of Investment</span>
              <p className="value">
                {proposalData?.investmentDuration} year(s)
              </p>
            </div>
            <div className="value-container">
              <span className="label">Risk Tolerance</span>
              <p className="value" style={{ textTransform: "capitalize" }}>
                {proposalData?.riskTolerance}
              </p>
            </div>
            <div className="value-container">
              <span className="label">
                {token.proposal_copy_investment_amount}
              </span>
              <p className="value">
                {getCurrencyValue(proposalData?.investmentAmount)}
              </p>
            </div>
          </Flex>

          <Flex gap={90} justify="space-between" style={{ marginBottom: 30 }}>
            <Flex flex="706px" justify="space-between" vertical>
              {productsLiveAssessment && (
                <ChartIncomeWithTypesLevel
                  height={273}
                  hideChartTypeButtons
                  productData={proposalData}
                  productsList={productsList}
                  productsLiveAssessment={productsLiveAssessment}
                />
              )}
            </Flex>

            <IncomeChartLegend />
          </Flex>

          <Flex gap={62}>
            <section className="section-planning-corner">
              <h5>Planning Corner</h5>
              <h3>Balancing Objectives</h3>
              <p>
                Growth investments can increase wealth over time, while
                income-generating assets provide stability and cash flow,
                especially during market downturns. This approach offers
                potential for capital appreciation while providing a safety net
                through consistent income, enhancing overall financial security
                and flexibility.
              </p>
            </section>

            <Flex
              className="selections-container"
              justify="space-between"
              style={{ width: 247 }}
            >
              <div className="value-container">
                <span className="label">Annual Income Target</span>
                <p className="value">
                  {getCurrencyValue(getAnnualIncomeTargetValue())}
                </p>
              </div>
            </Flex>
          </Flex>
        </main>
      </Flex>
    </StylesContainer>
  );
};

export default ReportPageProposalIncome;
