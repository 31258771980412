import { useContext } from "react";
import State from "../../../../../context";
import { Flex } from "antd";
import ReportHeader from "../../component/ReportHeader";
import ReportPageCover from "../../page/ReportPageCover";
import ReportPageIntroduction from "../../page/ReportPageIntroduction";
import ReportPageIpq1 from "../../page/ReportPageIpq1";
import ReportPageIpq2 from "../../page/ReportPageIpq2";
import ReportPageMilestones from "../../page/ReportPageMilestones";
import ReportPagePersonalityIntro from "../../page/ReportPagePersonalityIntro";
import ReportPagePersonalityUser from "../../page/ReportPagePersonalityUser";
import ReportPagePersonalitySpouse from "../../page/ReportPagePersonalitySpouse";
import ReportPagePersonalityCouple from "../../page/ReportPagePersonalityCouple";
import ReportPagePersonalityCommonBlind from "../../page/ReportPagePersonalityCommonBlind";
import ReportPagePersonalityUnique from "../../page/ReportPagePersonalityUnique";
import ReportPagePriorities from "../../page/ReportPagePriorities";
import ReportPageProposalDisclosure from "../../page/ReportPageProposalDisclosure";
import ReportPageProposalFactSheet from "../../page/ReportPageProposalFactSheet";
import ReportPageProposalGrowth from "../../page/ReportPageProposalGrowth";
import ReportPageProposalHowToGet from "../../page/ReportPageProposalHowToGet";
import ReportPageProposalIncome from "../../page/ReportPageProposalIncome";

import { getCoupleCalculations } from "../../../page/PageProposalDashboard/components/SectionPerspectiveCoupleResults";

const ReportPdfContentHolder = () => {
  const [state] = useContext(State);

  const guideInitialData = state.getPreferenceValue("guideInitialData");
  const perspectiveSpouse = state.getPreferenceValue("perspectiveSpouse");
  const perspectiveMy = state.getPreferenceValue("perspectiveMy");

  const isMarried = guideInitialData?.isMarried;

  const getCoupleName = () => {
    if (isMarried)
      return `${state.personalInfo?.firstName} & ${guideInitialData.spouseFirstName} ${state.personalInfo?.lastName}`;
    else return getUserName();
  };

  const getSpouseName = () =>
    `${guideInitialData?.spouseFirstName} ${guideInitialData?.spouseLastName}`;

  const getUserName = () =>
    `${state.personalInfo?.firstName} ${state.personalInfo?.lastName}`;

  const getSavedProposalData = () => {
    const productMap = state.getPreferenceValue("productMap");

    if (!productMap) return;

    if (state.activeGoal) {
      // return data for requested goal
      const savedProposalHistory = productMap[state.activeGoal];

      return savedProposalHistory[savedProposalHistory.length - 1];
    } else {
      // no requested goal, return data for first goal from the list
      const firstSavedProposalHistory = productMap[Object.keys(productMap)[0]];

      return firstSavedProposalHistory[firstSavedProposalHistory.length - 1];
    }
  };

  return (
    // PDF generator can work with hidden sections
    <div id="reportPdfContentHolder" style={{ display: "none" }}>
      <Flex gap={32} vertical>
        <ReportPageCover
          managerName={`${
            state.userManagerData?.personalInfo?.firstName ?? ""
          } ${state.userManagerData?.personalInfo?.lastName ?? ""}`}
          managerPhone={state.userManagerData?.personalInfo?.phoneNumber}
          personaName={getCoupleName()}
        />
        <ReportPageIntroduction
          managerName={`${
            state.userManagerData?.personalInfo?.firstName ?? ""
          } ${state.userManagerData?.personalInfo?.lastName ?? ""}`}
          managerPhone={state.userManagerData?.personalInfo?.phoneNumber}
          managerEmail={state.userManagerData?._id}
          personaName={getCoupleName()}
        />
        <ReportPagePersonalityIntro personaName={getCoupleName()} />
        {getSavedProposalData()?.investmentObjective === "growth" ? (
          <ReportPageProposalGrowth
            personaName={getCoupleName()}
            productsList={state.productsList}
            proposalData={getSavedProposalData()}
            setLoading={loading =>
              state.setKeyValue("loadingPdfContent", loading)
            }
          />
        ) : (
          <ReportPageProposalIncome
            personaName={getCoupleName()}
            productsList={state.productsList}
            proposalData={getSavedProposalData()}
            setLoading={loading =>
              state.setKeyValue("loadingPdfContent", loading)
            }
          />
        )}
        <ReportPageProposalHowToGet
          personaName={getCoupleName()}
          proposalData={getSavedProposalData()}
        />
        <ReportPagePersonalityUser
          personaName={getUserName()}
          perspectiveData={perspectiveMy}
        />
        {isMarried && (
          <>
            <ReportPagePersonalitySpouse
              personaName={getSpouseName()}
              perspectiveData={perspectiveSpouse}
            />
            <ReportPagePersonalityCouple
              personaName={getCoupleName()}
              userName={getUserName()}
              spouseName={getSpouseName()}
            />
            <ReportPagePersonalityCommonBlind
              coupleCalculations={getCoupleCalculations({
                perspectiveMy,
                perspectiveSpouse,
              })}
              personaName={getCoupleName()}
            />
            <ReportPagePersonalityUnique
              coupleCalculations={getCoupleCalculations({
                perspectiveMy,
                perspectiveSpouse,
              })}
              personaName={getCoupleName()}
              selectedPerspectiveUser={perspectiveMy?.selectedPersonality}
              selectedPerspectiveSpouse={perspectiveSpouse?.selectedPersonality}
              userFirstName={state.personalInfo?.firstName}
              spouseFirstName={guideInitialData?.spouseFirstName}
            />
          </>
        )}
        <ReportPagePriorities
          cards={
            isMarried
              ? (state.getPreferenceValue("prioritiesFamily")?.commonPicks ??
                state.getPreferenceValue("prioritiesFamilyQuickAdd")?.picked)
              : state.getPreferenceValue("prioritiesMy")?.picked
          }
          personaName={getCoupleName()}
          userLastName={state.personalInfo?.lastName}
        />
        <ReportPageMilestones
          milestonesQuestions={state.getPreferenceValue("milestonesQuestions")}
          personaName={getCoupleName()}
        />
        <ReportPageIpq1
          prospectObjective={state.getPreferenceValue("prospectObjective")}
        >
          <ReportHeader personaName={getCoupleName()} />
        </ReportPageIpq1>
        <ReportPageIpq2
          prospectObjective={state.getPreferenceValue("prospectObjective")}
        >
          <ReportHeader personaName={getCoupleName()} />
        </ReportPageIpq2>
        <ReportPageProposalFactSheet
          financialProductName={getSavedProposalData()?.productName}
        />
        <ReportPageProposalDisclosure
          financialProductName={getSavedProposalData()?.productName}
        />
      </Flex>
    </div>
  );
};

export default ReportPdfContentHolder;
