import { Flex } from "antd";
import ReportHeader from "../../component/ReportHeader";
import TableMilestones from "../../../page/PageProposalDashboard/components/SectionMilestonesQuestionnaire/components/TableMilestones";

import StylesContainer from "./style";

const ReportPageMilestones = ({ milestonesQuestions, personaName }) => (
  <StylesContainer id="reportPageMilestones">
    <div className="page-container">
      <ReportHeader personaName={personaName} />

      <div style={{ marginBottom: 74, marginTop: 24 }}>
        <h2>Milestones</h2>
        <p className="description">
          Context is key. Select the life events that apply to your current
          situation. You and your advisor will keep these Milestones in mind as
          you form a financial plan.
        </p>
      </div>

      {milestonesQuestions?.filter(
        it => (it.type === "marriage" || it.type === "career") && it.value
      )?.length ? (
        <Flex vertical>
          <h4>Marriage & Career</h4>
          <TableMilestones
            data={milestonesQuestions.filter(
              it => (it.type === "marriage" || it.type === "career") && it.value
            )}
            readonly
          />
        </Flex>
      ) : (
        ""
      )}

      {milestonesQuestions?.filter(
        it => (it.type === "health" || it.type === "family") && it.value
      )?.length ? (
        <Flex style={{ marginTop: 28 }} vertical>
          <h4>Health & Family</h4>
          <TableMilestones
            data={milestonesQuestions.filter(
              it => (it.type === "health" || it.type === "family") && it.value
            )}
            hideTitle
            readonly
          />
        </Flex>
      ) : (
        ""
      )}

      {milestonesQuestions?.filter(
        it => (it.type === "assets" || it.type === "generosity") && it.value
      )?.length ? (
        <Flex style={{ marginTop: 28 }} vertical>
          <h4>Assets, Liabilities & Generosity</h4>
          <TableMilestones
            data={milestonesQuestions
              .filter(
                it =>
                  (it.type === "assets" || it.type === "generosity") && it.value
              )
              .map((it, index) => ({
                ...it,
                key: index,
              }))}
            hideTitle
            readonly
          />
        </Flex>
      ) : (
        ""
      )}
    </div>
  </StylesContainer>
);

export default ReportPageMilestones;
