import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Button, Flex, Skeleton, Spin } from "antd";

import StyledContainer from "./style";

import {
  WITHDRAWAL_SEARCH_PARAMETER,
  WITHDRAWAL_START_ON_CHART_GAP,
} from "../../../../../proposal/constant";

import {
  cleanPercentValue,
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../../../../../../utils/helper/general";
import { getLiveAssessmentV2, getLiveSimulationV2 } from "../../../../request";

import { ReactComponent as Download } from "../../../../images/icon_download.svg";

const ProposalGoalPreviewIncome = ({
  Footer,
  handleDownloadProposal,
  productLiveAssessment,
  productsLiveSimulation,
  proposalData,
  productData,
}) => {
  const [loading, setLoading] = useState(false);
  const [liveAssessment, setLiveAssessment] = useState(
    productLiveAssessment?.main
  );
  const [liveSimulation, setLiveSimulation] = useState(productsLiveSimulation);

  const socialSecurity = proposalData?.socialSecurity ?? 0;
  const annuities = proposalData?.annuities ?? 0;
  const otherIncome = proposalData?.otherIncome ?? 0;

  useEffect(() => {
    // Fetch fresh calculations, if do not passed in the props
    if (liveAssessment && liveSimulation) return;

    setLoading(true);

    getLiveAssessmentV2(proposalData)
      .then(data => {
        setLiveAssessment(data.main);

        getLiveSimulationV2(proposalData)
          .then(data => setLiveSimulation(data))
          .catch(console.log)
          .finally(() => setLoading(false));
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const getInvestmentWithdrawalRanges = data => {
    if (!data) return;

    const result = [];
    let currentValue = null;
    let startYear = null;

    for (let i = 0; i < data.length; i++) {
      const [year, value] = data[i];

      if (currentValue !== value) {
        // if bigger than zero
        if (currentValue) {
          result.push(
            `${getCurrencyValueWithRoundSign(currentValue)} / year (${
              proposalData.birthYear + startYear
            }-${proposalData.birthYear + data[i - 1][0]})`
          );
        }
        currentValue = value;
        startYear = year;
      }
    }

    // if bigger than zero
    if (currentValue) {
      result.push(
        `${getCurrencyValueWithRoundSign(currentValue)} / year (${
          proposalData.birthYear + startYear
        }-${proposalData.birthYear + data[data.length - 1][0]})`
      );
    }

    return result.join("<br/>");
  };

  const getMonthlyIncomeInRetirement = () => {
    if (proposalData?.withdrawalLevel === undefined) return "";

    const yearCurrent = +dayjs().format("YYYY");
    const yearWithdrawalStart =
      yearCurrent +
      proposalData.yearToStartWithdrawals +
      WITHDRAWAL_START_ON_CHART_GAP;

    const finalBalanceBeforeWithdrawalsStart = liveAssessment?.find(it =>
      it.Date.includes(yearWithdrawalStart + "")
    )?.Value;

    if (isNaN(proposalData.withdrawalLevel))
      // percent value
      return (
        (finalBalanceBeforeWithdrawalsStart *
          cleanPercentValue(proposalData.withdrawalLevel)) /
        100 /
        12
      );

    // dollar value
    return proposalData.withdrawalLevel / 12;
  };

  if (loading) {
    return (
      <Skeleton active style={{ width: 691 }}>
        <Spin />
      </Skeleton>
    );
  }

  return (
    <StyledContainer gap={24} vertical>
      <Flex justify="space-between">
        <Flex vertical>
          <span>Objective</span>
          <h2 style={{ textTransform: "capitalize" }}>
            Drive {proposalData?.investmentObjective}
          </h2>
          <span>
            Risk Tolerance:{" "}
            <b style={{ textTransform: "capitalize" }}>
              {proposalData?.riskTolerance}
            </b>
          </span>
        </Flex>

        {handleDownloadProposal && (
          <Button
            className="button-download"
            icon={<Download />}
            onClick={handleDownloadProposal}
            shape="circle"
            type="text"
          />
        )}
      </Flex>

      <Flex gap={48}>
        <Flex gap={8} flex="1" vertical>
          <b style={{ fontSize: 22 }}>
            {getCurrencyValue(Math.round(getMonthlyIncomeInRetirement()))}
          </b>
          <span>Monthly Income in Retirement</span>
        </Flex>

        {liveSimulation && (
          <Flex gap={8} flex="1" vertical>
            <b style={{ fontSize: 22 }}>{liveSimulation?.chanceOfSuccess}%</b>
            <span>Chance Income will Last Through Retirement</span>
          </Flex>
        )}
      </Flex>

      <Flex gap={48}>
        <Flex flex="1" gap={12} vertical>
          <b
            style={{
              borderBottom: "1px solid rgba(201, 200, 200, 0.50)",
              padding: "8px 0",
            }}
          >
            Proposal Details
          </b>
          <Flex justify="space-between">
            <span>Investment Amount</span>
            <b>{getCurrencyValue(proposalData?.investmentAmount)}</b>
          </Flex>
          <Flex justify="space-between">
            <span>Length of Investment</span>
            <b>{proposalData?.investmentDuration} Years</b>
          </Flex>
          <Flex justify="space-between">
            <span>Contributions</span>
            <b>{getCurrencyValue(proposalData?.contributions / 12)} Monthly</b>
          </Flex>
          <Flex justify="space-between">
            <span>Annual Income Target</span>
            <b>
              {isNaN(proposalData?.withdrawalLevel)
                ? proposalData?.withdrawalLevel
                : getCurrencyValueWithRoundSign(
                    proposalData?.withdrawalLevel
                  )}{" "}
              / year
            </b>
          </Flex>
        </Flex>

        <Flex flex="1" gap={12} vertical>
          <b
            style={{
              borderBottom: "1px solid rgba(201, 200, 200, 0.50)",
              padding: "8px 0",
            }}
          >
            Income Sources in Retirement
          </b>
          <Flex justify="space-between">
            <span>Social Security</span>
            <b>{getCurrencyValueWithRoundSign(socialSecurity)} / year</b>
          </Flex>
          <Flex justify="space-between">
            <span>Annuities</span>
            <b>{getCurrencyValueWithRoundSign(annuities)} / year</b>
          </Flex>
          <Flex justify="space-between">
            <span>Other Income</span>
            <b>{getCurrencyValueWithRoundSign(otherIncome)} / year</b>
          </Flex>
          <Flex justify="space-between">
            <span style={{ width: 80 }}>Investment Withdrawal</span>
            <b
              dangerouslySetInnerHTML={{
                __html: getInvestmentWithdrawalRanges(
                  liveAssessment
                    // withdrawals included in the first month each year
                    ?.filter(it =>
                      it.Date.includes(
                        dayjs().format(WITHDRAWAL_SEARCH_PARAMETER)
                      )
                    )
                    ?.map(it => [
                      dayjs(it.Date).format("YYYY") - proposalData?.birthYear,
                      it.Withdrawals,
                    ])
                ),
              }}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex gap={12} vertical>
        <b>Investment Selection</b>

        <Flex
          gap={20}
          style={{
            border: "1px solid #C9C8C8",
            borderRadius: 12,
            padding: "20px 16px 16px",
          }}
          vertical
        >
          <b style={{ fontSize: 18 }}>{productData?.name}</b>

          <Flex className="container-portfolio-values" justify="space-between">
            <Flex gap={4} vertical>
              <span>Closest Match</span>
              <p className="value">{productData?.riskTolerance}</p>
            </Flex>
            <Flex gap={4} style={{ textAlign: "right" }} vertical>
              <span>Risk Score</span>
              <p className="value">{productData?.riskScore}</p>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {Footer && <Footer />}
    </StyledContainer>
  );
};

export default ProposalGoalPreviewIncome;
