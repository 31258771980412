import styled from "styled-components";

const StyledContainer = styled.div`
  ol {
    padding-left: 16px;

    li {
      margin-top: 8px;

      &::marker {
        font-weight: bold;
      }
    }
  }
`;

export default StyledContainer;
