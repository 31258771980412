import { DEFAULT_WITHDRAWAL_START_YEAR } from "../../../utils/constant";

export const CHART_VIEW_OBJECTIVE_GROWTH = ["projection", "contributions"];

export const CHART_VIEW_OBJECTIVE_INCOME = [
  "projection",
  "income",
  "contributions",
];

export const DEFAULT_FILTER_ASSET_MANAGERS_LEVEL = ["Level &reg;"];

export const FILTER_TAG_COLOR_LIST = [
  {
    label: "Alternative",
    color: "#9D415D",
  },
  {
    label: "Cash",
    color: "#71B03E",
  },
  {
    label: "Equity",
    color: "#5C88D2",
  },
  {
    label: "Fixed Income",
    color: "#E3945D",
  },
  {
    label: "Not Classified",
    color: "#E5E5E5",
  },
  {
    label: "Other",
    color: "#E6C235",
  },
];

export const HISTORICAL_CHART_TYPE_DEFAULT = "SPDR® S&P 500® ETF Trust";

export const LEAD_MAX_RATE_VALUE = 5;

export const NEWS_LIST_MOCK = [
  {
    body: `
    <ol>
    <li>SMCI shares increased after investigation cleared misconduct allegations.</li>
    <li>External experts confirmed no need for financial restatements for SMCI.</li>
    <li>Positive findings could enhance investor confidence in SMCI stock.</li>
    </ol>
    `,
    id: 1,
    minutes: 29,
    sources: 7,
    tags: [
      { label: "SMCI" },
      { background: "#D1EBAC", color: "#026F00", label: "Short Term Bullish" },
      { label: "Corporate Developments" },
    ],
    title: "Super Micro Computer Surges as Committee Finds No Misconduct",
  },
  {
    body: `
    <ol>
    <li>Stellantis CEO Carlos Tavares resigns abruptly amid board disagreements.</li>
    <li>New CEO search is expected to be completed within six months.</li>
    <li>Leadership change may impact investor confidence and company strategy.</li>
    </ol>
    `,
    id: 2,
    minutes: 29,
    sources: 55,
    tags: [
      { label: "STLA" },
      { background: "#FFE7E7", color: "#BA0202", label: "Short Term Bearish" },
      { label: "Corporate Developments" },
    ],
    title: "Stellantis CEO Carlos Tavares Resigns Amid Company Struggles",
  },
];

export const PDF_PAGE_LIST = [
  {
    type: "growth",
    nodeIdCluster: [
      "reportPageCover",
      "reportPageIntroduction",
      "reportPageProposalGrowth",
      "reportPageProposalHowToGet",
      "reportPageProposalDisclosure",
    ],
  },
  {
    type: "income",
    nodeIdCluster: [
      "reportPageCover",
      "reportPageIntroduction",
      "reportPageProposalIncome",
      "reportPageProposalHowToGet",
      "reportPageProposalDisclosure",
    ],
  },
];

export const PROPOSAL_DEFAULT_DATA_LEVEL = {
  birthYear: 1972,
  contributions: 12000,
  investmentAmount: 500000,
  investmentDuration: 20,
  investmentObjective: "growth",
  organization: "level2",
  riskTolerance: "Moderate",
  withdrawalLevel: "4%",
  yearToStartWithdrawals: DEFAULT_WITHDRAWAL_START_YEAR,
};

export const PROPOSAL_GOAL_TYPE_LEVEL = [
  {
    value: "growth",
    label: "Drive Growth",
  },
  {
    value: "income",
    label: "Drive Income",
  },
  {
    value: "growth_income",
    label: "Drive Growth & Income",
  },
];

export const PROPOSAL_SAMPLE_URL_LEVEL =
  "https://ontrajectory.s3.amazonaws.com/level/level_proposal_sample.pdf";

export const RISK_TYPES_LEVEL = [
  { value: "aggressive", label: "Aggressive" },
  { value: "growth", label: "Growth" },
  { value: "moderate", label: "Moderate" },
  { value: "conservative", label: "Conservative" },
  { value: "preservation", label: "Preservation" },
];
