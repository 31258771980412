import styled from "styled-components";

const StyledContainer = styled.div`
  .ant-btn {
    background: #fff;
    border-color: #888;
    color: #888;
    width: 45px;

    &.active {
      background: #428916;
      border-color: #428916;
      color: #fff;
    }
  }

  .ant-picker {
    background: #fff;
    border-color: #888;
    border-radius: 25px;
    width: 45px;

    .ant-picker-suffix {
      color: #888;
      margin: unset;
    }

    .ant-picker-input {
      justify-content: center;
    }

    .ant-picker-input > input {
      display: none;
    }
  }

  .ant-picker.active {
    background: #428916;
    border-color: #428916;

    .ant-picker-suffix {
      color: #fff;
    }
  }

  .container-note {
    background: #ecf5f5;
    border-radius: 12px;
    box-sizing: border-box;
    max-width: 820px;
    padding: 16px;

    .mdxeditor-root-contenteditable {
      height: calc(100% - 50px);

      ._contentEditable_uazmk_379 {
        height: 100%;
      }
    }

    .mdxeditor-toolbar {
      background: #ecf5f5;
    }
  }

  .ant-divider-horizontal {
    margin: 7px 0 0;
  }

  .ant-table-wrapper {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    width: 100%;

    table {
      border-spacing: 0 10px;
    }

    .ant-table-thead > tr > th {
      background: #fff;
      border: unset;
      color: #216799;
      padding: 0 10px;

      &::before {
        display: none;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        border-bottom: 1px solid #81b6dc;
        border-top: 1px solid #81b6dc;
        color: #222;
        line-height: 140%;
        padding: 12px;

        &:first-of-type {
          border-bottom-left-radius: 8px;
          border-left: 1px solid #81b6dc;
          border-top-left-radius: 8px;
        }

        &:last-of-type {
          border-bottom-right-radius: 8px;
          border-right: 1px solid #81b6dc;
          border-top-right-radius: 8px;
        }
      }

      .ant-input-outlined {
        border: unset;
        padding: unset;
      }
    }
  }
`;

export default StyledContainer;
