import { useContext } from "react";
import State from "../../../../../../../../context";
import { Button, Flex } from "antd";
import ChartPersonality from "../../../ChartPersonality";
import MenuActions from "../MenuActions";

import { isAdminView } from "../../../../../../../../utils/helper/specialized";
import { LIST_PERSPECTIVES_PROFILE } from "../../../../../../constant";

const ArticlePerspectives = ({ handlePerspectivesViewResults }) => {
  const [state] = useContext(State);

  const guideInitialData = state.getPreferenceValue("guideInitialData");
  const isMarried = guideInitialData?.isMarried;

  const selectedPersonalityMy =
    state.getPreferenceValue("perspectiveMy")?.selectedPersonality;
  const selectedPersonalitySpouse =
    state.getPreferenceValue("perspectiveSpouse")?.selectedPersonality;

  const isQuizCompleted = isMarried
    ? selectedPersonalityMy && selectedPersonalitySpouse
    : selectedPersonalityMy;

  const handleLaunchPerspectives = perspectiveFor => {
    state.setKeyValue("perspectiveFor", perspectiveFor);
    state.setKeyValue("surveyLinkFor", perspectiveFor);
    state.setKeyValue("openModalSurveyLink", true);
  };

  const handleViewPerspectiveCouple = () => {
    state.setKeyValue("viewPerspectiveCoupleResults", true);
    state.setKeyValue("showButtonBackToDashboard", true);
  };

  const handleActionReTakeQuiz = perspectiveFor => {
    state.setKeyValue("perspectiveFor", perspectiveFor);
    state.setKeyValue("openModalPerspectives", true);
  };

  const ButtonPerspectivesMy = () =>
    selectedPersonalityMy ? (
      <Flex gap={8}>
        <Button
          block
          disabled={state.loading}
          onClick={() => handlePerspectivesViewResults("perspectiveMy")}
          shape="round"
          style={{ background: "#209F00" }}
          type="primary"
        >
          View {state.personalInfo?.firstName}'s
        </Button>
        <MenuActions
          items={[
            {
              disabled: isAdminView(),
              key: "take",
              label: "Re-take quiz",
              onClick: () => handleActionReTakeQuiz("perspectiveMy"),
            },
            {
              disabled: isAdminView(),
              key: "send",
              label: "Re-send quiz",
              onClick: () => handleLaunchPerspectives("perspectiveMy"),
            },
          ]}
        />
      </Flex>
    ) : state.getPreferenceValue("perspectiveMy") ? (
      <Button
        disabled={state.loading || isAdminView()}
        onClick={() => handlePerspectivesViewResults("perspectiveMy")}
        shape="round"
        type="primary"
      >
        <Flex align="center" gap={10}>
          <span className="perspective-step-marker">1/2</span>
          {state.personalInfo?.firstName}: Launch
        </Flex>
      </Button>
    ) : (
      <Button
        disabled={state.loading || isAdminView()}
        onClick={() => handleLaunchPerspectives("perspectiveMy")}
        shape="round"
        type="primary"
      >
        <Flex gap={10}>{state.personalInfo?.firstName}: Launch</Flex>
      </Button>
    );

  const ButtonPerspectivesSpouse = () =>
    selectedPersonalitySpouse ? (
      <Flex gap={8}>
        <Button
          block
          disabled={state.loading}
          onClick={() => handlePerspectivesViewResults("perspectiveSpouse")}
          shape="round"
          style={{ background: "#209F00" }}
          type="primary"
        >
          View {guideInitialData?.spouseFirstName}'s
        </Button>
        <MenuActions
          items={[
            {
              disabled: isAdminView(),
              key: "take",
              label: "Re-take quiz",
              onClick: () => handleActionReTakeQuiz("perspectiveSpouse"),
            },
            {
              disabled: isAdminView(),
              key: "send",
              label: "Re-send quiz",
              onClick: () => handleLaunchPerspectives("perspectiveSpouse"),
            },
          ]}
        />
      </Flex>
    ) : state.getPreferenceValue("perspectiveSpouse") ? (
      <Button
        disabled={state.loading}
        onClick={() => handlePerspectivesViewResults("perspectiveSpouse")}
        shape="round"
        type="primary"
      >
        <Flex align="center" gap={10}>
          <span className="perspective-step-marker">1/2</span>
          {guideInitialData?.spouseFirstName}: Launch
        </Flex>
      </Button>
    ) : (
      <Button
        disabled={state.loading}
        onClick={() => handleLaunchPerspectives("perspectiveSpouse")}
        shape="round"
        type="primary"
      >
        <Flex gap={10}>{guideInitialData?.spouseFirstName}: Launch</Flex>
      </Button>
    );

  const ButtonPerspectivesCouple = () =>
    selectedPersonalityMy && selectedPersonalitySpouse ? (
      <Button
        disabled={state.loading}
        onClick={handleViewPerspectiveCouple}
        shape="round"
        style={{ background: "#209F00" }}
        type="primary"
      >
        View {state.personalInfo?.lastName}'s
      </Button>
    ) : (
      <Button disabled shape="round" type="primary">
        View {state.personalInfo?.lastName}'s
      </Button>
    );

  const ContentQuizCompleted = () => (
    <Flex gap={56} justify="space-between">
      <Flex gap={29} vertical>
        {selectedPersonalityMy && (
          <Flex gap={8} vertical>
            <b
              style={{
                fontSize: 17,
              }}
            >
              {state.personalInfo?.firstName}
            </b>
            <b style={{ color: "#4B82B1" }}>
              {
                state
                  .getPreferenceValue("perspectiveMy")
                  .calculation?.personalityList.find(
                    it => it.type === selectedPersonalityMy
                  ).value
              }
              % Match
              {state.getPreferenceValue("perspectiveMy").calculation
                .personalityList[0].type === selectedPersonalityMy && " - Best"}
            </b>
            <p
              dangerouslySetInnerHTML={{
                __html: LIST_PERSPECTIVES_PROFILE.find(
                  it => it.name === selectedPersonalityMy
                ).description,
              }}
              style={{ margin: 0 }}
            />
          </Flex>
        )}

        {selectedPersonalitySpouse && (
          <Flex gap={8} vertical>
            <b style={{ fontSize: 17 }}>{guideInitialData?.spouseFirstName}</b>
            <b style={{ color: "#4B82B1" }}>
              {
                state
                  .getPreferenceValue("perspectiveSpouse")
                  .calculation?.personalityList.find(
                    it => it.type === selectedPersonalitySpouse
                  ).value
              }
              % Match
              {state.getPreferenceValue("perspectiveSpouse").calculation
                .personalityList[0].type === selectedPersonalitySpouse &&
                " - Best"}
            </b>
            <p
              dangerouslySetInnerHTML={{
                __html: LIST_PERSPECTIVES_PROFILE.find(
                  it => it.name === selectedPersonalitySpouse
                )?.description,
              }}
              style={{ margin: 0 }}
            />
          </Flex>
        )}
      </Flex>

      <Flex gap={32} style={{ width: 277 }} vertical>
        <ChartPersonality
          examplePersonality={!isMarried && selectedPersonalityMy}
          height={277}
          sizeSmall
          width={277}
        />

        <Flex gap={8} vertical>
          <ButtonPerspectivesMy />

          {isMarried && (
            <>
              <ButtonPerspectivesSpouse />
              <ButtonPerspectivesCouple />
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );

  const ContentQuizNotCompleted = () => (
    <Flex align="center" gap={40} justify="space-between">
      <Flex className="description" gap={12} vertical>
        <h5>Get started</h5>
        <p>
          Once each party has completed the questionnaires, you can take the
          ‘Perspectives’ together. After that, the results will be displayed
          here.
        </p>
      </Flex>

      <Flex gap={8} style={{ width: 328 }} vertical>
        <ButtonPerspectivesMy />

        {isMarried && (
          <>
            <ButtonPerspectivesSpouse />
            <ButtonPerspectivesCouple />
          </>
        )}
      </Flex>
    </Flex>
  );

  return (
    <article>
      <Flex className="header" gap={8} vertical>
        <h4>Perspectives Results</h4>
        {isQuizCompleted && (
          <p>
            We’re all uniquely made. Your personal lens shapes how you view,
            interpret, and interact with money.
          </p>
        )}
      </Flex>

      <Flex className="container-description" vertical>
        {isQuizCompleted ? (
          <ContentQuizCompleted />
        ) : (
          <ContentQuizNotCompleted />
        )}
      </Flex>
    </article>
  );
};

export default ArticlePerspectives;
