import { Spin } from "antd";

import StylesContainer from "./style";

const OverlayPdf = ({ show }) => (
  <StylesContainer>
    <Spin
      fullscreen
      size="large"
      spinning={!!show} // required boolean value
      tip={
        <>
          <h3>Collecting documents and generating PDF...</h3>
          <p>
            Depending on the amount of selected pages, this could take up to a
            minute.
          </p>
          <p>
            If you cancel, or close your browser, the PDF will not be created.
          </p>
        </>
      }
    />
  </StylesContainer>
);

export default OverlayPdf;
