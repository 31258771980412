import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Button, Checkbox, Flex, theme, Table } from "antd";
import MenuActions from "../MenuActions";
import SliderRisk from "../../../../../../component/QuestionnaireContentIPQ/component/SliderRisk";

import StyledContainer from "./style";

import { isAdminView } from "../../../../../../../../utils/helper/specialized";
import {
  getCurrencyValue,
  getPercentValue,
} from "../../../../../../../../utils/helper/general";

import { ReactComponent as Warning } from "./image/warning.svg";

const ArticleInvesting = ({
  handleIpqCreate,
  handleIpqView,
  handleProposalCreate,
  handleProposalView,
  isIpqUser,
  isProposalSaved,
  proposalData,
  prospectObjective,
}) => {
  const { token } = theme.useToken();

  const isIpqSaved = !!prospectObjective;

  const getSliderPositionByRiskType = riskType => {
    switch (riskType?.toLowerCase()) {
      case "preservation":
        return 3;
      case "conservative":
        return 15;
      case "moderate":
        return 25;
      case "growth":
        return 35;
      case "equity":
        return 47;
    }
  };

  const ContentIpq = () =>
    isIpqSaved ? (
      <div className="container-description">
        <SliderRisk
          riskLeft={prospectObjective?.riskTolerance}
          riskRight={prospectObjective?.calculatedRiskTolerance}
          size="small"
          value={[
            getSliderPositionByRiskType(
              prospectObjective?.calculatedRiskTolerance
            ),
            getSliderPositionByRiskType(prospectObjective?.riskTolerance),
          ]}
        />

        {prospectObjective?.calculatedRiskTolerance?.toLowerCase() !==
          prospectObjective?.riskTolerance?.toLowerCase() && (
          <Flex
            gap={8}
            style={{
              background: "rgba(255, 255, 255, 0.50)",
              border: "1px solid #CFCBC3",
              borderRadius: 8,
              marginBottom: 37,
              padding: 16,
            }}
          >
            <Warning />
            <Flex vertical>
              <b>Be aware...</b>
              <span>
                Your personal risk score <b>does not match</b> the portfolio’s
                risk score.
              </span>
              <Flex
                align="center"
                gap={8}
                style={{
                  background: "#FFF",
                  borderRadius: 8,
                  marginTop: 8,
                  padding: "4px 8px",
                }}
              >
                <Checkbox checked disabled />I Understand
              </Flex>
            </Flex>
          </Flex>
        )}

        <Flex gap={16} justify="center">
          <Button
            onClick={handleIpqView}
            shape="round"
            style={{ background: "#209F00", width: 224 }}
            type="primary"
          >
            View IPQ
          </Button>
          <MenuActions
            items={[
              {
                disabled: isAdminView(),
                key: "take",
                label: "Re-take IPQ",
                onClick: handleIpqCreate,
              },
            ]}
          />
        </Flex>
      </div>
    ) : (
      <div className="container-description">
        <Flex
          align="center"
          gap={24}
          justify="center"
          style={{ padding: "24px 0" }}
          vertical
        >
          <p style={{ color: "#3375AD", fontSize: 24, margin: 0 }}>
            No saved IPQ
          </p>
          <Button
            disabled={isAdminView()}
            onClick={handleIpqCreate}
            shape="round"
            style={{ width: 214 }}
            type="primary"
          >
            New IPQ
          </Button>
        </Flex>
      </div>
    );

  const chartConfig = {
    chart: {
      backgroundColor: "transparent",
      height: 160,
      spacingTop: 0,
      type: "pie",
      width: 160,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        size: 158,
        innerSize: "60%",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        center: ["50%", "50%"],
        borderColor: "#ffffff",
        borderWidth: 2,
      },
      series: {
        animation: false,
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    colors: token.chart_colors,
    series: [
      {
        colorByPoint: true,
        data: proposalData?.assets?.map(it => ({
          name: it.assetName,
          y: it.investmentPercent,
        })),
        name: "Selected",
        size: "100%",
      },
    ],
  };

  const ContentProposal = () =>
    isProposalSaved ? (
      <div className="container-description">
        <Flex gap={24} vertical>
          <Flex vertical>
            <span>Portfolio:</span>
            <h2
              style={{
                color: "#3375AD",
                margin: 0,
              }}
            >
              {proposalData?.productName}
            </h2>
          </Flex>

          <Flex
            align="center"
            gap={8}
            justify="center"
            style={{
              borderRadius: 8,
              background: "#FFF",
              padding: 16,
            }}
          >
            <span>Investment Amount</span>
            <b style={{ fontSize: 21 }}>
              {getCurrencyValue(proposalData?.investmentAmount)}
            </b>
          </Flex>

          <Flex justify="space-between">
            <HighchartsReact highcharts={Highcharts} options={chartConfig} />
            <Table
              columns={[
                {
                  dataIndex: "assetName",
                  render: (value, _, rowIndex) => (
                    <div
                      className="cell-category-name"
                      style={{
                        background:
                          token.chart_colors[
                            rowIndex % token.chart_colors.length
                          ],
                      }}
                    >
                      {value}
                    </div>
                  ),
                  title: "Category",
                },
                {
                  align: "right",
                  dataIndex: "investmentPercent",
                  render: value => getPercentValue(value),
                  title: "%",
                },
              ]}
              dataSource={proposalData?.assets?.map((it, index) => ({
                ...it,
                key: index,
              }))}
              pagination={false}
              rowHoverable={false}
              size="small"
            />
          </Flex>

          <Flex justify="center">
            <Button
              onClick={handleProposalView}
              shape="round"
              style={{ background: "#209F00", width: 202 }}
              type="primary"
            >
              View Proposal
            </Button>
          </Flex>
        </Flex>
      </div>
    ) : (
      <div className="container-description">
        <Flex
          align="center"
          gap={24}
          justify="center"
          style={{ padding: "24px 0" }}
          vertical
        >
          <p style={{ color: "#3375AD", fontSize: 24, margin: 0 }}>
            No saved proposal
          </p>
          <Button
            disabled={isAdminView()}
            onClick={handleProposalCreate}
            shape="round"
            style={{ width: 214 }}
            type="primary"
          >
            New Proposal
          </Button>
        </Flex>
      </div>
    );

  return (
    <StyledContainer>
      <article>
        <Flex className="header" gap={8} vertical>
          <h4>Investing</h4>
          <p>
            Investing expresses your values through capital allocation. Your
            Portfolio reveals whether your financial strategies match your
            deepest convictions.
          </p>
        </Flex>

        <Flex gap={24}>
          {isIpqUser && <ContentIpq />}

          <ContentProposal />
        </Flex>
      </article>
    </StyledContainer>
  );
};

export default ArticleInvesting;
