export const defaultProposalData = {
  beginWithdrawal: null,
  contributions: 0,
  currentAge: 60,
  currentSpouseAge: 0,
  decisionInfluence: null,
  decliningMarketWithholdingPeriod: null,
  listAccounts: {},
  householdIncome: 100000,
  incomeExpectations: null,
  investmentAmount: 100000,
  investmentDuration: 10,
  investmentObjective: "growth",
  investmentKnowledge: null,
  investmentsToAlignWith: [],
  investmentsToAvoid: [],
  organization: "OneAscent",
  prospectType: "FinancialAdvisor",
  riskTolerance: "moderate",
  volatilityTolerance: null,
};

export const financialRiskToleranceCalculationData = {
  currentAgeRange: {
    "-45": 10,
    "45-55": 6,
    "56-65": 4,
    "66-75": 2,
    "75-": 0,
  },
  incomeExpectations: {
    "I fear I might lose my job or I plan to retire": 1,
    "I anticipate my income will probably trend downward": 2,
    "I expect a fairly level income": 7,
    "I anticipate a steadily growing income": 10,
  },
  beginWithdrawal: {
    "-3": 1,
    "3-5": 4,
    "5-10": 7,
    "10-": 10,
  },
  investmentKnowledge: {
    "I know virtually nothing about investing": 1,
    "My understanding of the markets and investing is minimal": 3,
    "Somewhat knowledgeable. I have a basic comprehension of investing": 7,
    "Highly knowledgeable. I have a good understanding of how the stock and bond markets work, and I spend considerable time keeping up with financial matters": 10,
  },
  decisionInfluence: {
    "I am mostly influenced by the potential loss": 1,
    "I am equally influenced by potential loss or gain": 5,
    "I am mostly influenced by the potential gain": 10,
  },
  volatilityTolerance: {
    "Any decrease in value would concern me": 0,
    "Up to 10% downside ($100,000 falling to $90,000)": 8,
    "Up to 20% downside ($100,000 falling to $80,000)": 12,
    "Up to 30% downside ($100,000 falling to $70,000)": 16,
    "I would not worry about performance for that short of a period": 20,
  },
  decliningMarketWithholdingPeriod: {
    "Less than 1 year": 1,
    "Between 1-5 years": 5,
    "More than 5 years": 10,
  },
};

export const calculateFinancialRiskToleranceMatrix = [
  ["P", "P", "P", "P", "P", "C", "C", "C", "C", "C", "M", "M", "M", "M"],
  ["P", "P", "C", "C", "C", "C", "M", "M", "M", "M", "G", "G", "G", "G"],
  ["P", "C", "C", "C", "C", "M", "M", "M", "M", "G", "G", "G", "G", "E"],
  ["P", "C", "C", "C", "M", "M", "M", "M", "G", "G", "G", "G", "E", "E"],
  ["C", "C", "C", "C", "M", "M", "M", "M", "G", "G", "G", "G", "E", "E"],
  ["C", "C", "C", "M", "M", "M", "M", "G", "G", "G", "G", "E", "E", "E"],
];

export const QUIZ_RISK_SELECTION_TABLE_DATA = [
  {
    alternatives: "20%",
    equity: "80%",
    fixedIncome: "0,0%",
    key: "equity",
    riskTolerance: "Equity",
  },
  {
    alternatives: "20%",
    equity: "60%",
    fixedIncome: "20%",
    key: "growth",
    riskTolerance: "Growth",
  },
  {
    alternatives: "20%",
    equity: "50%",
    fixedIncome: "30%",
    key: "moderate",
    riskTolerance: "Moderate",
  },
  {
    alternatives: "15%",
    equity: "32.5%",
    fixedIncome: "52.5%",
    key: "conservative",
    riskTolerance: "Conservative",
  },
  {
    alternatives: "0,0%",
    equity: "0,0%",
    fixedIncome: "100%",
    key: "preservation",
    riskTolerance: "Preservation",
  },
];
