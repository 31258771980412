import styled from "styled-components";
import { Flex } from "antd";

const StyledContainer = styled(Flex)`
  margin-top: 77px;

  .row-account-label {
    color: #a5a3a3;
  }

  .ant-input {
    border-color: #00bdc4;
    border-radius: 12px;
    color: #354a5f;
    max-width: 361px;
    padding: 7px 15px;
    width: 100%;
  }

  .ant-input-number {
    border-color: #00bdc4;
    border-radius: 12px;
    color: #354a5f;
    padding: 4px 11px;
  }

  .ant-select {
    width: 186px;

    &.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
      padding: 7px 15px;
    }

    .ant-select-selector {
      border-color: #00bdc4;
      border-radius: 12px;
    }

    .ant-select-selection-item {
      font-size: 16px;
    }

    .ant-select-arrow {
      color: #354a5f;
    }
  }

  .ant-btn.button-add-note {
    background: #fff;
    border: 1px solid #888;
    width: 127px;
  }

  .container-custom-risk {
    overflow: hidden;
    padding: 30px 0 46px;
    width: 431px;

    .ant-table-wrapper {
      background: rgba(255, 255, 255, 0.2);
      border-bottom: 5px solid #8596a5;
      border-left: 1px solid #8596a5;
      border-radius: 8px;
      border-right: 1px solid #8596a5;
      border-top: 1px solid #8596a5;
      box-shadow: 0 18px 32px 0 rgba(0, 0, 0, 0.25);
      padding: 24px;

      .ant-table-row {
        .ant-table-cell {
          font-size: 12px;
        }

        &.active {
          background: #4b82b1;
          color: #fff;

          .ant-table-cell {
            border-top: 3px solid #4b4747;
            border-bottom: 3px solid #4b4747;

            &:first-of-type {
              border-left: 3px solid #4b4747;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }

            &:last-of-type {
              border-right: 3px solid #4b4747;
              border-bottom-right-radius: 4px;
              border-top-right-radius: 4px;
            }
          }
        }
      }
    }
  }
`;

export default StyledContainer;
