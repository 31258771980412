import styled from "styled-components";

const StylesContainer = styled.div`
  .page-container {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    position: relative;
    width: 1230px;
    padding: 71px 80px 20px 78px;
  }

  h2 {
    color: #000;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0;
  }

  .description {
    color: #000;
    font-size: 16px;
    font-style: normal;
    line-height: 140%;
    margin: 0;
  }

  .sub-title {
    color: #000;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin: 0 0 10px;
  }

  h4 {
    border-bottom: 1px solid #aaa;
    color: #071a4d;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin: 0 0 8px;
  }

  .container-note {
    background: #ecf5f5;
    border-radius: 8px;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    padding: 0 16px 10px;

    .mdxeditor-root-contenteditable {
      height: calc(100% - 50px);

      ._contentEditable_uazmk_379 {
        height: 100%;
      }
    }

    .mdxeditor-toolbar {
      background: #ecf5f5;
    }
  }

  .ant-table-wrapper .ant-table-tbody .ant-table-cell {
    padding: 7px 16px;

    .checkbox-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export default StylesContainer;
