import { useContext } from "react";
import dayjs from "dayjs";
import State from "../../../../../../../../context";
import { MDXEditor } from "@mdxeditor/editor";
import { Button, Flex, Popover } from "antd";
import ModalMilestones from "../../../ModalMilestones";

import StyledContainer from "./style";

import { isAdminView } from "../../../../../../../../utils/helper/specialized";

const ArticleMilestones = () => {
  const [state] = useContext(State);

  const milestonesQuestions = state.getPreferenceValue("milestonesQuestions");

  const getMilestonesListByType = type => {
    const filteredList = milestonesQuestions?.filter(it => it.value === type);

    return filteredList ? (
      <ol>
        {filteredList.map((it, index) => (
          <li key={index}>
            {it.date && (
              <>
                <b>{dayjs(it.date).format("MM/DD/YYYY")}</b>
                <br />
              </>
            )}
            {it.label}{" "}
            {it.note && (
              <>
                {"- "}
                <Popover
                  content={<MDXEditor markdown={it.note ?? ""} readOnly />}
                  trigger="click"
                >
                  <a>
                    <u>Note</u>
                  </a>
                </Popover>
              </>
            )}
          </li>
        ))}
      </ol>
    ) : (
      ""
    );
  };

  const handleMilestonesEdit = () => {
    state.setKeyValue("openModalMilestones", false);
    state.setKeyValue("viewMilestonesQuestionnaire", true);
    state.setKeyValue("showButtonBackToDashboard", true);
  };

  const handleLaunchMilestones = () => {
    state.setKeyValue("surveyLinkFor", "milestones");
    state.setKeyValue("openModalSurveyLink", true);
  };

  const ButtonMilestones = () =>
    milestonesQuestions ? (
      <Button
        block
        onClick={() => state.setKeyValue("openModalMilestones", true)}
        shape="round"
        style={{ background: "#209F00" }}
        type="primary"
      >
        View Milestones
      </Button>
    ) : (
      <Button
        block
        disabled={isAdminView()}
        onClick={handleLaunchMilestones}
        shape="round"
        type="primary"
      >
        Launch Milestones
      </Button>
    );

  return (
    <StyledContainer>
      <article>
        <Flex className="header" gap={8} vertical>
          <h4>Milestones</h4>
          <p>
            Milestones mark meaningful financial progress. They show how far
            you’ve come and what’s next.
          </p>
        </Flex>

        <Flex className="container-description" gap={29} vertical>
          <Flex gap={48}>
            <Flex vertical>
              <b>Past</b>
              {getMilestonesListByType("recent")}
            </Flex>
            <Flex vertical>
              <b>Upcoming</b>
              {getMilestonesListByType("upcoming")}
            </Flex>
          </Flex>
        </Flex>

        <div style={{ width: 180 }}>
          <ButtonMilestones />
        </div>

        <ModalMilestones
          handleMilestonesEdit={handleMilestonesEdit}
          milestonesQuestions={milestonesQuestions}
          onCancel={() => state.setKeyValue("openModalMilestones", false)}
          open={state.openModalMilestones}
        />
      </article>
    </StyledContainer>
  );
};

export default ArticleMilestones;
