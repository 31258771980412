import { useContext } from "react";
import State from "../../../../../../../../context";
import { Button, Flex } from "antd";
import MenuActions from "../MenuActions";
import ModalPrioritiesQuickAdd from "../../../ModalPrioritiesQuickAdd";

import { isAdminView } from "../../../../../../../../utils/helper/specialized";

const ArticlePriorities = () => {
  const [state] = useContext(State);

  const guideInitialData = state.getPreferenceValue("guideInitialData");
  const isMarried = guideInitialData?.isMarried;

  const prioritiesMy = state.getPreferenceValue("prioritiesMy");
  const prioritiesSpouse = state.getPreferenceValue("prioritiesSpouse");
  const prioritiesFamily = state.getPreferenceValue("prioritiesFamily");

  const isQuizCompleted = isMarried ? prioritiesFamily : prioritiesMy;

  const handleActionQuickAdd = prioritiesFor => {
    state.setKeyValue("prioritiesFor", prioritiesFor);
    state.setKeyValue("openModalPrioritiesQuickAdd", true);
  };

  const handleActionReTakeQuiz = prioritiesFor => {
    state.setKeyValue("viewResultsPrioritiesQuizMode", false);
    state.setKeyValue("prioritiesFor", prioritiesFor);
    state.setKeyValue("reTakePrioritiesQuizMode", true);
    state.setKeyValue("openModalPriorities", true);
  };

  const handleCloseModalPrioritiesQuickAdd = () => {
    state.setKeyValue("prioritiesFor", null);
    state.setKeyValue("openModalPrioritiesQuickAdd", false);
  };

  const handleLaunchPriorities = prioritiesFor => {
    state.setKeyValue("viewResultsPrioritiesQuizMode", false);
    state.setKeyValue("prioritiesFor", prioritiesFor);
    state.setKeyValue("reTakePrioritiesQuizMode", false);
    state.setKeyValue("surveyLinkFor", prioritiesFor);
    state.setKeyValue("openModalSurveyLink", true);
  };

  const handlePrioritiesQuickAdd = newValues => {
    if (newValues.filter(it => it.priority !== 0).length !== 5) {
      // user removed one of 5 required selections
      state.showWarning("You cannot have less than 5 selections prioritized.");
      return;
    }

    state.setKeyValue("loading", true);

    const sortedPickedLabels = newValues
      .filter(it => it.priority)
      .sort((a, b) => a.priority - b.priority)
      .map(it => it.label);

    state
      .setPreferenceValue(state.prioritiesFor, {
        ...state.getPreferenceValue(state.prioritiesFor),
        picked: sortedPickedLabels,
        root: state
          .getPreferenceValue(state.prioritiesFor)
          ?.root?.filter(it => it !== null),
      })
      .then(() => {
        state.showSuccess("Quick-add Priorities saved");
        state.setKeyValue("openModalPrioritiesQuickAdd", false);
        state.setKeyValue("prioritiesFor", null);
      })
      .catch(error => state.showError(error))
      .finally(() => state.setKeyValue("loading", false));
  };

  const handleViewPrioritiesIndividual = prioritiesFor => {
    state.setKeyValue("reTakePrioritiesQuizMode", false);
    state.setKeyValue("viewResultsPrioritiesQuizMode", true);
    state.setKeyValue("prioritiesFor", prioritiesFor);
    state.setKeyValue("openModalPriorities", true);
  };

  const handleViewPrioritiesCouple = () => {
    state.setKeyValue("viewResultsPrioritiesQuizMode", false);
    state.setKeyValue("viewPrioritiesCoupleResults", true);
    state.setKeyValue("showButtonBackToDashboard", true);
  };

  const ButtonPrioritiesQuickAddAnswers = () => {
    const shouldShowButton = () => {
      if (isMarried) {
        // hidden if client quiz or spouse quiz passed
        return !prioritiesMy && !prioritiesSpouse;
      } else {
        // hidden if client quiz passed
        return !prioritiesMy;
      }
    };

    return (
      shouldShowButton() && (
        <Button
          disabled={isAdminView()}
          onClick={() =>
            handleActionQuickAdd(
              isMarried ? "prioritiesFamilyQuickAdd" : "prioritiesMy"
            )
          }
          shape="round"
          type="primary"
        >
          Quick Add Answers
        </Button>
      )
    );
  };

  const ButtonPrioritiesMy = () =>
    prioritiesMy ? (
      <Flex gap={16}>
        <Button
          block
          disabled={state.loading}
          onClick={() => handleViewPrioritiesIndividual("prioritiesMy")}
          shape="round"
          style={{ background: "#209F00" }}
          type="primary"
        >
          View {state.personalInfo?.firstName}'s
        </Button>
        <MenuActions
          items={[
            {
              disabled: isAdminView(),
              key: "take",
              label: "Re-take quiz",
              onClick: () => handleActionReTakeQuiz("prioritiesMy"),
            },
            {
              disabled: isAdminView(),
              key: "send",
              label: "Re-send quiz",
              onClick: () => handleLaunchPriorities("prioritiesMy"),
            },
            {
              disabled: isAdminView(),
              hidden: prioritiesMy,
              onClick: () => handleActionQuickAdd("prioritiesMy"),
              key: "add",
              label: "Quick-add answers",
            },
          ].filter(it => !it.hidden)}
        />
      </Flex>
    ) : (
      <Button
        disabled={
          state.loading ||
          isAdminView() ||
          state.getPreferenceValue("prioritiesFamilyQuickAdd")
        }
        onClick={() => handleLaunchPriorities("prioritiesMy")}
        shape="round"
        type="primary"
      >
        {state.personalInfo?.firstName}: Launch
      </Button>
    );

  const ButtonPrioritiesSpouse = () =>
    prioritiesSpouse ? (
      <Flex gap={16}>
        <Button
          block
          disabled={state.loading}
          onClick={() => handleViewPrioritiesIndividual("prioritiesSpouse")}
          shape="round"
          style={{ background: "#209F00" }}
          type="primary"
        >
          View {guideInitialData?.spouseFirstName}'s
        </Button>
        <MenuActions
          items={[
            {
              disabled: isAdminView(),
              onClick: () => handleActionReTakeQuiz("prioritiesSpouse"),
              key: "take",
              label: "Re-take quiz",
            },
            {
              disabled: isAdminView(),
              onClick: () => handleLaunchPriorities("prioritiesSpouse"),
              key: "send",
              label: "Re-send quiz",
            },
            {
              disabled: isAdminView(),
              hidden: prioritiesSpouse,
              onClick: () => handleActionQuickAdd("prioritiesSpouse"),
              key: "add",
              label: "Quick-add answers",
            },
          ].filter(it => !it.hidden)}
        />
      </Flex>
    ) : (
      <Button
        disabled={
          state.getPreferenceValue("prioritiesFamilyQuickAdd") || state.loading
        }
        onClick={() => handleLaunchPriorities("prioritiesSpouse")}
        shape="round"
        type="primary"
      >
        {guideInitialData?.spouseFirstName}: Launch
      </Button>
    );

  const ButtonPrioritiesCouple = () =>
    prioritiesFamily ? (
      <Button
        disabled={state.loading}
        onClick={handleViewPrioritiesCouple}
        shape="round"
        style={{ background: "#209F00" }}
        type="primary"
      >
        View {state.personalInfo?.lastName}'s
      </Button>
    ) : (
      <Button
        disabled={state.loading || !prioritiesMy || !prioritiesSpouse}
        onClick={handleViewPrioritiesCouple}
        shape="round"
        type="primary"
      >
        View {state.personalInfo?.lastName}'s
      </Button>
    );

  const getSelectedValues = () => {
    if (isMarried) {
      return (
        prioritiesFamily?.commonPicks ??
        state.getPreferenceValue("prioritiesFamilyQuickAdd")?.picked ??
        null
      );
    } else {
      return prioritiesMy?.picked ?? null;
    }
  };

  const ContentQuizCompleted = () => (
    <Flex align="center" gap={40} justify="space-between">
      {getSelectedValues() && (
        <ol style={{ lineHeight: "24px", paddingLeft: 16 }}>
          {getSelectedValues().map((label, index) => (
            <li key={index}>
              {label?.replace("[my]", "")?.replace("[spouse]", "")}
            </li>
          ))}
        </ol>
      )}

      <Flex gap={8} style={{ width: 277 }} vertical>
        <ButtonPrioritiesMy />
        {isMarried && (
          <>
            <ButtonPrioritiesSpouse />
            <ButtonPrioritiesCouple />
          </>
        )}
        <ButtonPrioritiesQuickAddAnswers />
      </Flex>
    </Flex>
  );

  const ContentQuizNotCompleted = () => (
    <Flex align="center" gap={40} justify="space-between">
      <Flex className="description" gap={12} vertical>
        <h5>Get started</h5>
        <p>
          Once each party has completed the questionnaires, you can view the
          couple’s priorities here.
        </p>
      </Flex>

      <Flex gap={8} style={{ width: 328 }} vertical>
        <ButtonPrioritiesMy />
        {isMarried && (
          <>
            <ButtonPrioritiesSpouse />
            <ButtonPrioritiesCouple />
          </>
        )}
        <ButtonPrioritiesQuickAddAnswers />
      </Flex>
    </Flex>
  );

  return (
    <article>
      <Flex className="header" gap={8} vertical>
        <h4>Priorities Results</h4>
        <p>
          Priorities are the specific things you value most highly and choose to
          focus your time, energy, and resources on.
        </p>
      </Flex>

      <Flex className="container-description" vertical>
        {isQuizCompleted ? (
          <ContentQuizCompleted />
        ) : (
          <ContentQuizNotCompleted />
        )}
      </Flex>

      <ModalPrioritiesQuickAdd
        loading={state.loading}
        onCancel={handleCloseModalPrioritiesQuickAdd}
        open={state.openModalPrioritiesQuickAdd}
        onSave={handlePrioritiesQuickAdd}
        selectedValues={state.getPreferenceValue(state.prioritiesFor)?.picked}
        showWarning={state.showWarning}
      />
    </article>
  );
};

export default ArticlePriorities;
