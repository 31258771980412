import { useContext } from "react";
import State from "../../../../../../context";
import { Button, Flex } from "antd";
import ModalLevelGoalSettings from "../ModalLevelGoalSettings";
import ModalLevelProductDetails from "../../../../components/ModalLevelProductDetails";
import ProposalGoalPreviewGrowth from "../ProposalGoalPreviewGrowth";
import ProposalGoalPreviewIncome from "../ProposalGoalPreviewIncome";

import StylesContainer from "./style";

import { PROPOSAL_DEFAULT_DATA_LEVEL } from "../../../../constants";

import {
  archiveProposalGoal,
  restoreProposalGoal,
} from "../../../../../../utils/request/manager";
import { isLockedByAdminView } from "../../../../../../utils/helper/specialized";
import { addGoal } from "../../../../request";

import { ReactComponent as IconPlus } from "../../../../images/icon_plus_outlined.svg";

const ViewAllGoal = ({ handleDownloadProposal, handleViewGoal }) => {
  const [state] = useContext(State);

  const productMap = state.getPreferenceValue("productMap");

  const handleAddNewGoal = newGoalData => {
    state.setKeyValue("loading", true);

    addGoal({
      email: state._id,
      proposalData: {
        ...PROPOSAL_DEFAULT_DATA_LEVEL,
        ...newGoalData,
      },
    })
      .then(() =>
        state.setUserData().then(() => {
          state.setKeyValue("openModalLevelGoalSettings", false);
          state.setKeyValue("loading", false);
          state.showSuccess("New Goal Added!");
        })
      )
      .catch(error => {
        state.showError(error);
        state.setKeyValue("loading", false);
      });
  };

  const handleArchiveGoal = goalId => {
    archiveProposalGoal({
      email: state._id,
      productMapId: goalId,
      organization: state.organization?.name,
    })
      .then(() => {
        state.showSuccess("Goal archived");
        state.setUserData();
      })
      .catch(console.log);
  };

  const handleCardClick = cardId => {
    state.setKeyValue("productDetailsId", cardId);
    state.setKeyValue("openModalProductDetails", true);
  };

  const handleRestoreGoal = goalId => {
    restoreProposalGoal({
      email: state._id,
      productMapId: goalId,
      organization: state.organization?.name,
    })
      .then(() => {
        state.showSuccess("Goal restored");
        state.setUserData();
      })
      .catch(console.log);
  };

  const handleDownloadProposalClick = activeGoal => {
    handleDownloadProposal(activeGoal);
  };

  const ProposalGoalFooter = ({ goalId }) => (
    <Flex justify="center">
      <Button onClick={() => handleViewGoal(goalId)} shape="round">
        View Proposal
      </Button>
    </Flex>
  );

  return (
    <StylesContainer>
      <header>
        <h3>All Proposals</h3>

        <Button
          disabled={isLockedByAdminView({
            managerAccess: state.managerAccess,
          })}
          icon={<IconPlus />}
          onClick={() => state.setKeyValue("openModalLevelGoalSettings", true)}
          shape="round"
          style={{ height: 36 }}
          type="primary"
        >
          New Goal
        </Button>
      </header>
      <main>
        <Flex align="stretch" gap={32} wrap>
          {Object.keys(productMap).map(goalId => {
            const proposalData =
              productMap[goalId][productMap[goalId].length - 1];

            const productData = state.productsList?.find(
              it => it._id === proposalData.productId
            );

            return proposalData?.investmentObjective === "growth" ? (
              <ProposalGoalPreviewGrowth
                Footer={() => <ProposalGoalFooter goalId={goalId} />}
                handleDownloadProposal={() => handleDownloadProposal(goalId)}
                key={goalId}
                proposalData={proposalData}
                productData={productData}
              />
            ) : (
              <ProposalGoalPreviewIncome
                Footer={() => <ProposalGoalFooter goalId={goalId} />}
                handleDownloadProposal={() => handleDownloadProposal(goalId)}
                key={goalId}
                proposalData={proposalData}
                productData={productData}
              />
            );
          })}
        </Flex>
      </main>

      <ModalLevelGoalSettings
        onCancel={() => state.setKeyValue("openModalLevelGoalSettings", false)}
        onSuccess={handleAddNewGoal}
        open={state.openModalLevelGoalSettings}
      />
      <ModalLevelProductDetails
        handleClose={() => state.setKeyValue("openModalProductDetails", false)}
        investmentAssumptions={state.investmentAssumptions}
        open={state.openModalProductDetails}
        product={state.productsList?.find(
          it => it._id === state.productDetailsId
        )}
        showError={state.showError}
      />
    </StylesContainer>
  );
};

export default ViewAllGoal;
