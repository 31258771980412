import { useEffect, useState } from "react";
import { Flex, Skeleton, Spin, Button } from "antd";
import { Doughnut } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Tooltip } from "chart.js";

ChartJS.register(ArcElement, Tooltip);

import StyledContainer from "./style";

import {
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../../../../../../utils/helper/general";
import { getLiveSimulationV2 } from "../../../../request";

import { ReactComponent as Download } from "../../../../images/icon_download.svg";

const ProposalGoalPreviewGrowth = ({
  calculatedSimulation,
  Footer,
  handleDownloadProposal,
  proposalData,
  productData,
}) => {
  const [loading, setLoading] = useState(false);
  const [liveSimulation, setLiveSimulation] = useState(calculatedSimulation);

  useEffect(() => {
    // Fetch fresh calculations, if do not passed in the props
    if (liveSimulation) return;

    setLoading(true);

    const requestBody = {
      ...proposalData,
      historicalAnalysis: true,
      inputSettings: {},
      montecarlo: true,
      productId: proposalData?.productId,
    };

    getLiveSimulationV2(requestBody)
      .then(data => setLiveSimulation(data))
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Skeleton active style={{ width: 471 }}>
        <Spin />
      </Skeleton>
    );
  }

  return (
    <StyledContainer className="growth" gap={24} vertical>
      <Flex justify="space-between">
        <Flex vertical>
          <span>Objective</span>
          <h2>Drive Growth</h2>
          <span>
            Risk Tolerance:{" "}
            <b style={{ textTransform: "capitalize" }}>
              {proposalData?.riskTolerance}
            </b>
          </span>
        </Flex>

        {handleDownloadProposal && (
          <Button
            className="button-download"
            icon={<Download />}
            onClick={handleDownloadProposal}
            shape="circle"
            type="text"
          />
        )}
      </Flex>

      <Flex gap={28}>
        <div className="container-chart">
          <Doughnut
            data={{
              labels: [],
              datasets: [
                {
                  backgroundColor: ["#0D63CD", "#EDEDED"],
                  circumference: 270,
                  cutout: "75%",
                  data: [80, 20],
                  rotation: 225,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
              borderRadius: 20,
              borderWidth: 0,
            }}
          />
        </div>
        <Flex justify="space-around" vertical>
          <b style={{ fontSize: 22 }}>80%</b>
          <div>Chance this investment returns between</div>
          {liveSimulation && (
            <b style={{ fontSize: 17 }}>
              {getCurrencyValueWithRoundSign(
                liveSimulation.Percentile10thMC.at(-1)
              )}
              {" - "}
              {getCurrencyValueWithRoundSign(
                liveSimulation.Percentile90thMC.at(-1)
              )}
            </b>
          )}
        </Flex>
      </Flex>

      <Flex gap={12} vertical>
        <b
          style={{
            borderBottom: "1px solid rgba(201, 200, 200, 0.50)",
            padding: "8px 0",
          }}
        >
          Proposal Details
        </b>
        <Flex justify="space-between">
          <span>Investment Amount</span>
          <b>{getCurrencyValue(proposalData?.investmentAmount)}</b>
        </Flex>
        <Flex justify="space-between">
          <span>Length of Investment</span>
          <b>{proposalData?.investmentDuration} Years</b>
        </Flex>
        <Flex justify="space-between">
          <span>Target Amount</span>
          <b>{getCurrencyValue(proposalData?.targetAmount)}</b>
        </Flex>
        <Flex justify="space-between">
          <span>Contributions</span>
          <b>{getCurrencyValue(proposalData?.contributions / 12)} Monthly</b>
        </Flex>
      </Flex>

      <Flex gap={12} vertical>
        <b>Investment Selection</b>

        <Flex
          gap={20}
          style={{
            border: "1px solid #C9C8C8",
            borderRadius: 12,
            padding: "20px 16px 16px",
          }}
          vertical
        >
          <b style={{ fontSize: 18 }}>{productData?.name}</b>

          <Flex className="container-portfolio-values" justify="space-between">
            <Flex gap={4} vertical>
              <span>Closest Match</span>
              <p className="value">{productData?.riskTolerance}</p>
            </Flex>
            <Flex gap={4} style={{ textAlign: "right" }} vertical>
              <span>Risk Score</span>
              <p className="value">{productData?.riskScore}</p>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {Footer && <Footer />}
    </StyledContainer>
  );
};

export default ProposalGoalPreviewGrowth;
