import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import dayjs from "dayjs";
import { Flex } from "antd";
import ChartSkeleton from "../../../../../../atom/ChartSkeleton";

import { WITHDRAWAL_SEARCH_PARAMETER } from "../../../../../proposal/constant";

import {
  convertDecimalIntoDate,
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
  getPercentValue,
} from "../../../../../../utils/helper/general";

const SectionPlanning = ({
  calculationLiveAssessment,
  calculationMc,
  etfData,
  loading,
  product,
}) => {
  const getSeries = (series = []) => {
    calculationLiveAssessment?.main &&
      series.push({
        color: "#537CF1",
        data: calculationLiveAssessment.main
          ?.filter(it => it.Date.includes(WITHDRAWAL_SEARCH_PARAMETER))
          ?.map(it => [+dayjs(it.Date).format("YYYY"), Math.round(it.Value)]),
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        name: "Calculated progress",
        zIndex: 3,
        zoneAxis: "x",
      });

    if (calculationMc) {
      // MC analysis
      const xAxisValues = calculationLiveAssessment.main
        .filter(it => it.Date.includes(WITHDRAWAL_SEARCH_PARAMETER))
        .map(it => +dayjs(it.Date).format("YYYY"))
        .filter(year => year !== +dayjs().format("YYYY")); // start chart from the next year

      const getRangeData = list =>
        xAxisValues.map((date, index) => [
          date,
          calculationMc.Percentile0thMC[index] < 0
            ? 0
            : calculationMc.Percentile0thMC[index],
          list[index],
        ]);

      calculationMc.Percentile90thMC &&
        series.push({
          color: "transparent",
          data: getRangeData(calculationMc.Percentile90thMC),
          fillColor: "#d6eefe",
          lineWidth: 0,
          name: "Monte Carlo 90th Percentile",
          type: "arearange",
          zIndex: 1,
        });

      calculationMc.Percentile10thMC &&
        series.push({
          color: "transparent",
          data: getRangeData(calculationMc.Percentile10thMC),
          fillColor: "#dbc7e4",
          lineWidth: 0,
          name: "Monte Carlo 10th Percentile",
          type: "arearange",
          zIndex: 2,
        });
    }

    return series;
  };

  const chartConfig = {
    chart: {
      type: "spline",
      height: 300,
      borderWidth: 0,
      spacingTop: 25,
      spacingRight: 25,
      spacingBottom: 24,
      backgroundColor: "transparent",
    },
    credits: false,
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    series: getSeries(),
    title: {
      text: "",
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        return `
        <p><b>Date:</b> ${convertDecimalIntoDate(this.x)}</p>
        <p><b>${this.series.name}:</b> ${getCurrencyValue(this.y)}</p>
      `;
      },
    },
    xAxis: {
      lineColor: "transparent",
      tickWidth: 0,
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#516474",
        },
        formatter: data => `'${data.value.toString().slice(2)}`,
      },
    },
    yAxis: {
      min: 0,
      opposite: true,
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#354A5F",
        },
        formatter: data => getCurrencyValueWithRoundSign(data.value),
      },
    },
  };

  return (
    <>
      <ChartSkeleton loading={loading} height={332}>
        <HighchartsReact highcharts={Highcharts} options={chartConfig} />
      </ChartSkeleton>

      <h4>Planning Projection Statistics</h4>
      <Flex gap={16}>
        <div className="value-container">
          <span>Expected Return</span>
          <p>
            {getPercentValue(
              product?.properties?.mcExpectedReturn ??
                Math.round(etfData?.JamesSteinMean * 10000) / 100
            )}
          </p>
        </div>
        <div className="value-container">
          <span>Standard Deviation</span>
          <p>
            {getPercentValue(
              product?.properties?.mcVolatilityReturn ??
                Math.round(etfData?.Volatility * 10000) / 100
            )}
          </p>
        </div>
      </Flex>
    </>
  );
};

export default SectionPlanning;
