import { useCallback, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { theme } from "antd";
import ChartSkeleton from "../../../../../../atom/ChartSkeleton";

import { WITHDRAWAL_SEARCH_PARAMETER } from "../../../../../proposal/constant";

import { chartRender as _chartRender } from "./chart-addons";
import {
  convertDecimalIntoDate,
  getCurrencyValue,
  getCurrencyValueWithRoundSign,
} from "../../../../../../utils/helper/general";
import { isGrowthTypeRiskTolerance } from "../../../../../../utils/helper/specialized";

import target_marker from "../../../../../../icon/target_marker.svg";

const ChartProjection = ({
  activeStressTestData,
  mcData,
  loading,
  productsList,
  productsLiveAssessment,
  proposalData,
  setActiveStressTestData,
  stressorCalculations,
  viewMode,
  isStressorMode,
}) => {
  const { token } = theme.useToken();
  const chartRef = useRef(null);
  const [growthChartSeries, setGrowthChartSeries] = useState([]);

  useEffect(() => {
    if (viewMode === "pdf") {
      // required for Astor & Rivershares pdf generators
      const storedData = localStorage.getItem("growthChartSvg");
      if (!storedData) {
        const interval = setInterval(() => {
          const growthChartSvg = chartRef?.current?.chart?.getSVG();

          growthChartSvg &&
            localStorage.setItem("growthChartSvg", growthChartSvg);
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [viewMode]);

  useEffect(() => {
    if (productsLiveAssessment?.length) {
      const series = [];

      productsLiveAssessment.map((it, index) => {
        series.push({
          color: it.color || token.comparison_products_colors[index],
          dashStyle: it.shape,
          data: it.main
            ?.filter(it => it.Date.includes(WITHDRAWAL_SEARCH_PARAMETER))
            ?.map(it => [+dayjs(it.Date).format("YYYY"), Math.round(it.Value)]),
          lineWidth: 4,
          marker: {
            enabled: false,
          },
          name: productsList.find(product => product._id === it.productId)
            ?.name,
          zIndex: 10,
          zoneAxis: "x",
        });

        if (it.gFutureProgress?.length) {
          series.push({
            color: "grey",
            dashStyle: "Dash",
            data: it.gFutureProgress,
            lineWidth: 4,
            name: "Future progress",
          });
        }
      });

      if (
        isGrowthTypeRiskTolerance(proposalData?.investmentObjective) &&
        proposalData?.targetAmount &&
        productsLiveAssessment?.length
      ) {
        const currentYear = +dayjs().format("YYYY");

        const targetAmountYear =
          proposalData.investmentObjective === "growth"
            ? +dayjs(
                productsLiveAssessment[0].main
                  .filter(it => it.Date.includes(WITHDRAWAL_SEARCH_PARAMETER))
                  .at(-1).Date
              ).format("YYYY")
            : currentYear + +proposalData.yearToStartWithdrawals + 1;

        series.push({
          name: "Target Amount",
          color: "#E4A428",
          data: [[targetAmountYear, proposalData.targetAmount]],
          marker: {
            enabled: true,
            symbol: `url(${target_marker})`,
          },
        });
      }

      if (stressorCalculations) {
        series.push({
          name: activeStressTestData.label,
          lineWidth: 4,
          color: "#1E1919",
          dashStyle: "Dot",
          marker: {
            enabled: false,
          },
          data: stressorCalculations.event[0].data
            .filter(it => it.Date.includes(WITHDRAWAL_SEARCH_PARAMETER)) // only first month values
            .map(it => [+dayjs(it.Date).format("YYYY"), it.Value]),
        });
      }

      if (mcData) {
        const xAxisValues = productsLiveAssessment[0].main
          .filter(it => it.Date.includes(WITHDRAWAL_SEARCH_PARAMETER))
          .map(it => +dayjs(it.Date).format("YYYY"))
          .filter(year => year !== +dayjs().format("YYYY")); // start chart from the next year

        const getRangeData = list =>
          xAxisValues.map((date, index) => [
            date,
            mcData.Percentile0thMC[index] < 0
              ? 0
              : mcData.Percentile0thMC[index],
            list[index],
          ]);

        mcData.Percentile90thMC &&
          series.push({
            color: "transparent",
            data: getRangeData(mcData.Percentile90thMC),
            fillColor: "#d6eefe",
            lineWidth: 0,
            name: "Monte Carlo 90th Percentile",
            type: "arearange",
            zIndex: 1,
          });

        mcData.Percentile10thMC &&
          series.push({
            color: "transparent",
            data: getRangeData(mcData.Percentile10thMC),
            fillColor: "#dbc7e4",
            lineWidth: 0,
            name: "Monte Carlo 10th Percentile",
            type: "arearange",
            zIndex: 2,
          });
      }

      setGrowthChartSeries(series);
    }
  }, [mcData, proposalData, productsLiveAssessment, stressorCalculations]);

  const onRangeChange = ({ durationYears, startYear }) => {
    setActiveStressTestData(lastState => ({
      ...lastState,
      durationYears,
      startYear,
    }));
  };

  const chartRender = useCallback(
    chart => {
      return _chartRender(chart, activeStressTestData, onRangeChange);
    },
    [activeStressTestData]
  );

  const chartConfig = {
    chart: {
      events: {
        render: function () {
          chartRender && chartRender(this);
        },
      },
      type: "spline",
      height: 300,
      borderWidth: 0,
      spacingTop: 25,
      spacingRight: 25,
      spacingBottom: 24,
      backgroundColor: "transparent",
    },
    credits: false,
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: !!stressorCalculations,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    series: growthChartSeries,
    title: {
      text: "",
    },
    tooltip: {
      enabled: !isStressorMode,
      useHTML: true,
      formatter: function () {
        const currentYear = +dayjs().format("YYYY");
        const date =
          currentYear === this.x
            ? dayjs().format("MM/DD/YYYY") // show today as line start
            : convertDecimalIntoDate(this.x); // show last day of the year

        return `
        <p><b>Date:</b> ${date}</p>
        <p><b>${this.series.name}:</b> ${getCurrencyValue(this.y)}</p>
      `;
      },
    },
    xAxis: {
      lineColor: "transparent",
      tickWidth: 0,
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#516474",
        },
        formatter: data => `'${data.value.toString().slice(2)}`,
      },
    },
    yAxis: {
      min: 0,
      opposite: true,
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 14,
          fontFamily: "Roboto, sans-serif",
          color: "#354A5F",
        },
        formatter: data => getCurrencyValueWithRoundSign(data.value),
      },
    },
  };

  return (
    <ChartSkeleton loading={loading} height={300}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartConfig}
        ref={chartRef}
      />
    </ChartSkeleton>
  );
};

export default ChartProjection;
