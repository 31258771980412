import styled from "styled-components";

const StyledContainer = styled.div`
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #fff;
  }

  .ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: #209f00;
  }
`;

export default StyledContainer;
