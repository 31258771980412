import { Flex, Slider } from "antd";

import StyledContainer from "./style";

const SliderRisk = ({
  onChange,
  onAfterChange,
  riskLeft,
  riskRight,
  size,
  value,
}) => (
  <StyledContainer>
    <Flex align="center" justify="space-between" style={{ marginBottom: 21 }}>
      <Flex style={{ color: "#3375AD", width: 180 }} vertical>
        <h3 className="label-risk-type">{riskLeft}</h3>
        <span>IPQ Score</span>
      </Flex>

      <span style={{ color: "#A5A3A3", fontSize: 16 }}>vs.</span>

      <Flex
        style={{ color: "#4B4747", textAlign: "right", width: 180 }}
        vertical
      >
        <h3 className="label-risk-type">{riskRight}</h3>
        <span>Personal Risk Score</span>
      </Flex>
    </Flex>
    <Slider
      className={size}
      range={Array.isArray(value)}
      value={value}
      min={0}
      max={50}
      step={1}
      tooltip={{ open: false }}
      onChange={onChange}
      onAfterChange={onAfterChange}
    />
  </StyledContainer>
);

export default SliderRisk;
